@use 'styles/theme.module' as *;

@mixin flag(
  $bg: #ffffff,
  $color: #de5000,
  $direction: right,
  $tail-length: 0.8em,
  $build-flag: true
) {
  // ======= Relative Variables =======
  $p: 0.3em;

  // ======= Flag Body =======
  @if $build-flag {
    // ~~ Display ~~
    display: inline-block;
    // ~~ Box Layout ~~
    padding: $p;
    padding-#{$direction}: $p;
    // ~~ Positioning ~~
    margin-#{$direction}: $tail-length;
    position: relative;
    // ~~ Typography ~~
    text-align: center;
    vertical-align: middle;
    line-height: 1;
    font-size: 14px;
    font-weight: 600;
  }
  // ~~ Themeing ~~
  color: $color;
  background: $bg;

  // ======= Flag Tails =======
  &:before,
  &:after {
    @if $build-flag {
      content: '';
      width: 0;
      height: 0;
      border-#{$direction}: $tail-length solid transparent;
      #{$direction}: -#{$tail-length};
      position: absolute;
      top: 0;
    }
    border-top: (0.5em + $p) solid $bg;
  }
  &:after {
    @if $build-flag {
      top: auto;
      bottom: 0;
    }
    border-top: none;
    border-bottom: (0.5em + $p) solid $bg;
  }
}

.reversed {
  @include flag($direction: left);
  top: 0;
  right: 0;
  position: absolute;
}

.box_card {
  display: grid;
  cursor: pointer;

  &:hover .card_content {
    background-color: #1a1125a3;
    // width: 103%;
    .action {
      display: flex;
    }
  }

  .card {
    overflow: hidden;
    position: relative;
    height: 55px;
    max-height: 90px;
    grid-area: 1 / 1;
    border-radius: 8px;
    .img {
      overflow: hidden;
      display: flex;
      justify-content: center;
      border-radius: 8px;
      border-bottom: 3px solid $white;
    }
  }

  .card_content {
    grid-area: 1 / 1;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    position: relative;
    transition:
      background-color,
      width 0.4s ease-out;
    width: 100%;
    max-height: 55px;
    .action {
      display: none;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      // height: 100%;
      .play {
        width: 42px;
        height: 21px;
        margin-top: 18px;
        font-weight: 600;
        background: transparent;
        border: 0;
        color: $white;
        text-transform: capitalize;
        &:hover {
          color: $mainOrange;
        }
      }
      .demo {
        width: 32px;
        height: 21px;
        margin-top: 3px;
        font-weight: 600;
        font-size: 10px !important;
        background: transparent;
        border: 0.5px solid;
        color: $white;
        border-color: $white;
        text-transform: capitalize;

        &:hover {
          background: $mainOrange;
          color: $white;
        }
      }
    }
  }
}
