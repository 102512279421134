@use 'styles/theme.module' as *;

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 260px;
  padding: 12px 24px;
  .modal_title {
    display: flex;
    flex-direction: column;
    margin-top: 1;
    gap: 5px;
    button {
      min-width: 300px;
      background-color: $green;
      color: $white;
    }
    img {
      width: 128px;
      margin: 0 auto;
    }
    .wallet_info {
      text-align: center;
      font-size: 0.8rem;
      color: $lightGrey;
      margin-top: 6px
    }
  }
  .action,
  .action_demonination {
    flex-wrap: wrap;
    width: 100%;
    margin: 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .playing_btn,
    .close_btn {
      margin: 6px;
      font-weight: 600;
      color: $white;
      background: $mainOrange;
      border-color: $mainOrange;
      text-transform: capitalize;
      @include btnRed;
      &:hover {
        @include btnRedHover;
      }
    }
    .game {
      position: relative;
    }
    .bonus,
    .game {
      .transfer_input {
        width: 200px;
        height: 40px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        .currency_name {
          text-transform: uppercase;
          padding: 9px;
          background-color: $mainOrange;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          transform: translateX(-3px);
        }
      }

      .bonus_button {
        @include btnOrange;
        &:hover {
          @include btnOrangeHover;
        }
      }

      .img {
        height: 140px;
        overflow: hidden;
        margin: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 100px;
        }
      }

      .checkbox_wrapper * {
        -webkit-tap-highlight-color: transparent;
        outline: none;
      }

      .checkbox_wrapper input[type='checkbox'] {
        display: none;
      }

      .checkbox_wrapper {
        label {
          --size: 34px;
          --shadow: calc(var(--size) * 0.07) calc(var(--size) * 0.1);

          position: relative;
          display: block;
          width: var(--size);
          height: var(--size);
          margin: 0 auto;
          background-color: $lightRed;
          border-radius: 50%;
          box-shadow: 0 var(--shadow) #ffbeb8;
          cursor: pointer;
          transition:
            0.2s ease transform,
            0.2s ease background-color,
            0.2s ease box-shadow;
          overflow: hidden;
          z-index: 1;
        }
      }

      .checkbox_wrapper label:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        width: calc(var(--size) * 0.7);
        height: calc(var(--size) * 0.7);
        margin: 0 auto;
        background-color: $white;
        transform: translateY(-50%);
        border-radius: 50%;
        box-shadow: inset 0 var(--shadow) #ffbeb8;
        transition:
          0.2s ease width,
          0.2s ease height;
      }

      .checkbox_wrapper label:hover:before {
        width: calc(var(--size) * 0.55);
        height: calc(var(--size) * 0.55);
        box-shadow: inset 0 var(--shadow) #ff9d96;
      }

      .checkbox_wrapper label:active {
        transform: scale(0.9);
      }

      .checkbox_wrapper .tick_mark {
        position: absolute;
        top: 2px;
        right: 0;
        left: calc(var(--size) * -0.05);
        width: calc(var(--size) * 0.6);
        height: calc(var(--size) * 0.6);
        margin: 0 auto;
        margin-left: calc(var(--size) * 0.14);
        transform: rotateZ(-40deg);
      }

      .checkbox_wrapper .tick_mark:before,
      .checkbox_wrapper .tick_mark:after {
        content: '';
        position: absolute;
        background-color: $white;
        border-radius: 2px;
        opacity: 0;
        transition:
          0.2s ease transform,
          0.2s ease opacity;
      }

      .checkbox_wrapper .tick_mark:before {
        left: 0;
        bottom: 0;
        width: calc(var(--size) * 0.1);
        height: calc(var(--size) * 0.3);
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.23);
        transform: translateY(calc(var(--size) * -0.68));
      }

      .checkbox_wrapper .tick_mark:after {
        left: 0;
        bottom: 0;
        width: 100%;
        height: calc(var(--size) * 0.1);
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.23);
        transform: translateX(calc(var(--size) * 0.78));
      }

      .checkbox_wrapper input[type='checkbox']:checked + label {
        background-color: #07d410;
        box-shadow: 0 var(--shadow) #92ff97;
      }

      .checkbox_wrapper input[type='checkbox']:checked + label:before {
        width: 0;
        height: 0;
      }

      .checkbox_wrapper input[type='checkbox']:checked + label .tick_mark:before,
      .checkbox_wrapper input[type='checkbox']:checked + label .tick_mark:after {
        transform: translate(0);
        opacity: 1;
      }
    }
  }
  .action_demonination {
    flex-direction: column;
    min-height: 200px;
    button {
      min-width: 200px;
      @include btnRed;
      &:hover {
        @include btnRedHover;
      }
    }
  }
  .isActive {
    color: $warning;
    font-family: bolder;
    :global(.Mui-checked) {
      color: $warning;
    }
  }
}
