@use 'styles/theme.module' as *;

.card {
  max-width: 450px;
  width: 400px;
  margin: auto;

  @include media($until: $sm) {
    width: 240px;
  }
  @include media($until: $xss) {
    width: 200px;
  }

  .content_text {
    text-align: center;
    margin-bottom: 18px;
    font-size: 0.8rem;
    @include media($until: $sm) {
      font-size: 0.6rem;
    }
  }
  .title {
    text-transform: uppercase;
    font-weight: bolder;
    margin-bottom: 10px;
    @include media($until: $sm) {
      font-size: 0.6rem;
    }
  }
  :global(.MuiCardMedia-root) {
    background-size: cover;
    margin-top: 22px;
    @include media($until: $sm) {
      background-size: 100%;
      height: 116px;
    }
  }
}
