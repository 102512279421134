@use 'styles/theme.module' as *;

.content {
  display: flex;
  justify-content: center;
  min-height: 600px;
  width: 100%;
  padding-top: 20px;
  color: $warning;
  &.isSuccess{
  color: $green;
  }
}
