@use 'styles/theme.module' as *;

:global(.MuiTooltip-tooltip) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  top: -4px;

  @include media($until: $xss) {
    top: -30px;
    left: -30px;
  }

  a {
    color: $white;
    font-size: 0.9rem;
    width: fit-content;
    border-bottom: thin solid red;
    margin: 0 5px 5px;
    &:hover {
      color: $mainBlue;
    }
  }
}
