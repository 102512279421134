@use 'styles/theme.module' as *;

.main {
  min-width: 96px;
  display: flex;
  align-items: center;
  .lang_text {
    margin-left: 6px;
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    color: $white;
  }
}
.upperCase {
  text-transform: uppercase;
}
