@use 'styles/theme.module' as *;

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
  width: 68vw;
  margin: 0 auto;
  max-width: 1300px;

  @include media($until: $sm) {
    min-height: 650px;
  }

  .title {
    margin: 20px 0 10px 0;
    background-image: linear-gradient($lightRed 0%, $mainOrange 80%);
    background-clip: text;
    width: 100%;
    padding: 12px 24px;
    p {
      color: transparent;
      font-size: 1.4rem;
      font-weight: 800;
    }
  }
  .section {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    flex-direction: column;
    align-items: center;

    @include media($until: $sm) {
      width: 100vw;
      flex-direction: column;
      padding: 0;
      margin-top: 0;
    }
    .content {
      display: flex;
      margin-top: 24px;
      @include media($until: $md) {
        width: 100vw;
        flex-direction: column;
        padding: 0 4px;
        margin-top: 0;
      }
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 5px;
        padding: 5px;
        cursor: pointer;

        p {
          font-size: 0.8rem;
          margin: 0 22px 0 0;
          @include media($until: $md) {
            margin: 0;
          }
          @include media($until: $sm) {
            text-align: right;
          }
        }

        .img {
          @include btnMagenta;
          width: auto;
          height: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $lightOrange;
          border-radius: 16px;
          padding: 5px 20px;
          max-width: 380px;
          min-width: 274px;

          @include media($until: $md) {
            width: 100%;
            max-width: unset;
            min-width: unset;
          }

          &:hover {
            @include btnMagentaHover;
            img {
              animation: fallAway forwards 1s ease-out 1;
            }
          }
          img {
            width: 130px;
            transform: translate(-29px, -28px) rotate(342deg);

            @include media($until: $md) {
              width: 100px;
              transform: translate(-10px, 0px) rotate(342deg);
            }

            @include media($until: $sm) {
              width: 80px;
              transform: translate(-10px, 0px) rotate(342deg);
            }
          }
        }

        @include media($until: $sm) {
          height: auto;
          width: auto;
          font-size: 0.8rem;
          min-height: unset;
        }
      }
    }
  }
}

@keyframes fallAway {
  0% {
    transform: translate(-29px, -28px);
    opacity: 1;
  }
  25% {
    transform: rotateZ(-20deg);
  }
  100% {
    // top: 400px;
    transform: rotateZ(-5deg);
    opacity: 1;
  }
}

.modal {
  @include media($until: $sm) {
    width: 100vw !important;
    padding: 24px;
  }
}
