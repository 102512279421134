@use 'styles/theme.module' as *;

.main {
  width: 100%;

  .error {
    input {
      border-color: $error;
      color: $error;
    }
  }

  .sign_btn {
    margin-top: 6px;
    font-weight: 600;
    color: $white;
    border-color: $mainOrange;
    text-transform: capitalize;
    border-radius: 10px;
    @include btnRed;
    &:hover {
      @include btnRedHover;
    }
  }
}

.box {
  display: flex;
  flex-direction: column;
  align-items: normal;
  overflow: auto;
  max-height: calc(100vh - 60px);
  overflow-x: hidden;
  padding: 12px;
  will-change: scroll-position;
  scrollbar-color: rgba(67, 75, 83, 0.4) transparent;
  scrollbar-width: thin;
  .input_content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: stretch;
    &.is_modal {
      flex-direction: row;
    }
    .left_side {
      margin: 10px 0px 4px 0;
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
    }

    :global(.MuiFormControl-root) {
      width: 100%;
      min-width: 100%;
      margin-bottom: 16px;
    }
    .promoCode {
      background-color: #1c2437;
      border-radius: 10px;
      padding: 0 10px;
    }
    .switchers {
      display: flex;
      .switcher {
        margin:0 2px;
        .label {
          margin: 8px 0;
        }
    &.blinking {
        border: 1.4px solid transparent;
        border-radius: 8px;
        animation: border-blink 1s infinite;
      }
      }
      &.displayColumns {
        flex-direction: column;
      }
    
    }
  }

  .action {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .have_acount {
      display: flex;
      justify-content: center;
      margin: 12px;
      align-items: center;
      font-size: 14px;
    }

    .social {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .divider_part {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .divider {
          width: 25%;
          min-width: 130px;
          height: 4px;
          border-bottom: 1px solid $white;
        }

        .or_text {
          margin: 6px;
          text-transform: capitalize;
        }
      }

      .icons_part {
        display: flex;

        .icons {
          cursor: pointer;
          width: 42px;
          height: 22px;
          background-color: $white;
          margin: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      &.hidden {
        display: none;
      }
    }
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    .currency {
      text-align: left;
      padding: 2px;
      font-size: 0.8rem;
      margin-bottom: 4px;
      text-indent: 8px;
    }
  }

  .info_text {
    display: flex;
    align-items: baseline;

    &.hidden {
      display: none;
    }

    img {
      margin-right: 8px;
    }

    span {
      text-align: left;
      font-size: 0.8rem;
    }
  }

  .action_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  a.link {
    margin: 0 4px;
  }

  .label {
    font-size: 0.8rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    height: 45px;

    &.is_modal {
      justify-content: flex-start;

      .title {
        color: $lightRed;
        font-size: 18px;
        text-indent: 24px;
        margin-top: 14px;
      }
    }

    .hidden {
      display: none;
    }
  }
}

.modal_signup:global(.MuiContainer-root) {
  display: flex;
  padding-left: 0;
  min-width: 320px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../assets/heroImg/fon.webp');
  max-height: calc(100vh - 60px);
  @include media($until: $md) {
    flex-direction: column-reverse;
    background-image: none;
  }

  form {
    flex-direction: column;
    max-width: 440px;
    min-width: 420px;
    max-height: calc(100vh - 33px);
    padding: 10px 0px 10px 24px;

    @include media($until: $md) {
      max-width: unset;
      min-width: unset;
      width: 100%;
    }

    @include media($until: 378) {
      padding: 10px 0;
    }
  }

  @include media($until: 378) {
    padding-right: 8px;
    min-width: 274px;
  }
}

.modal_bg {
  display: flex;
  max-width: 420px;
  min-height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;

  img.hero {
    display: none;

    @include media($from: $md) {
      display: flex;
      height: 100%;
      width: auto;
      max-height: 760px;
    }
  }

  img.logo {
    display: none;

    @include media($until: $md) {
      display: block;
    }
  }
  @include media($until: $md) {
    min-height: 44px;
  }
}

.divider {
  margin: 20px;
  height: 2px;
  width: 20%;
  background: $white;
}

.dialog {
  background-color: $darkGreen;
  button {
    background-color: $mainOrange;
  }
}

@keyframes border-blink {
  0%,
  100% {
    border-color: transparent;
  }
  50% {
    border-color: $mainOrange;
  }
}
