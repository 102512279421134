@use 'styles/theme.module' as *;

.main_area {
  padding: 0 12px;
  min-width: 550px;
  height: calc(100vh - 100px);
  max-height: calc(100vh - 150px);
  overflow: hidden auto;

  button {
    color: $white;
  }

  .main_title {
    text-transform: uppercase;
    margin: 0 0 12px 0;
    color: $lightRed;
  }

  .methods_block {
    background-color: #161d31;
    border-radius: 15px;
    padding: 12px;
    height: auto;
    min-height: 300px;
    max-height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
      @include media($until: $xsm) {
        font-size: 0.8rem;

        p {
          font-size: 0.8rem;
        }
      }
    }

    .current_amount {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      p {
        font-size: 0.8rem;
      }
      @include media($until: $xsm) {
        flex-direction: column;
        align-items: flex-end;

        p {
          font-size: 0.8rem;
        }
      }
    }

    .promo {
      margin: 10px 0;
      background-color: #3d4760;
      height: 68px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;

      .currency_name {
        text-transform: uppercase;
        padding: 9px;
        background-color: $mainOrange;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        transform: translateX(-3px);
      }
    }

    .own_tab {
      padding: 12px 8px;
      min-height: 200px;
      width: 100%;
      overflow: hidden auto;
      display: flex;
      justify-content: center;

      .deposit_block
       {
        width: 100%;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
        gap: 12px;
        margin: 24px 0;
        // max-height: 500px;
        @include media($until: 448) {
          grid-template-columns: repeat(3,minmax(90px, 0.6fr));
        }

        @include media($from: 449, $until: $sm) {
          grid-template-columns: repeat(auto-fill, minmax(90px, 110px));
        }

        .deposit_item,
        .deposit_native {
          display: flex;
          flex-direction: column;
          background-color: #3d4760;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          width: 141px;
          padding: 8px;
          margin: 0 auto;
          cursor: pointer;
          min-height: 200px;
          position: relative;
          @include media($until: $sm) {
            width: 114px;
          }
          @include media($until: $xss) {
            width: 94px;
          }

          span {
            font-size: 8px;
            text-align: center;
            margin-top: 2px;
          }
          img {
            width: 100%;
            max-width: 90px;
          }

          &:hover {
            background-color: #545f78dd;
          }

          &.isActive {
            box-shadow: inset 0px 1px 9px 2px $red;
          }
        }
      }
      .deposit_block_native {
        grid-template-columns: repeat(1, minmax(90px, 1fr));
        margin-left: 14px;
      }
      .deposit_volk {
        width: 150px;
        overflow: hidden;
        display: flex;
        margin: 8px 0 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .media {
          width: 100%;
          border-radius: 15px;
          overflow: hidden;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            opacity: 0.8;
            transition: opacity 0.2s ease-in;
            width: 100%;
          }
        }
        .text_volk {
          span {
            font-size: 10px;
          }
        }
        &:hover {
          .text_volk {
            span {
              color: $mainOrange;
            }
          }
          .media {
            img {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .promo_block {
    background-color: #3d4760;
    border-radius: 15px;
    margin: 20px 0 0;

    .promo {
      margin: 10px 0;
      background-color: #3d4760;
      height: 68px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      &.withdraw {
        height: auto;
        align-items: baseline;
        @include media($until: $sm) {
          flex-direction: column;
          height: auto;
          align-items: center;
        }
        .select {
          margin-left: 20px;
          @include media($until: $sm) {
            margin-top: 20px;
            margin-left: 0;
          }
        }
      }
    }
  }

  .bonus {
    margin: 10px 0;
    background-color: #3d4760;
    height: auto;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;

    .bonus_block {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 12px;

      .bonus_item {
        margin: 4px;
        display: flex;
        flex-direction: column;
        background-image: url('https://unlimcasino20.com/_nuxt/alien_gift_box.62255c27.svg');
        background-position: center right;
        background-size: 50%;
        background-repeat: no-repeat;
        justify-content: center;
        align-items: flex-start;
        border-radius: 8px;
        padding: 12px;
        border: 1px solid #3d4760;
        cursor: pointer;

        &:hover {
          background-color: #3d476031;
          border: 1px solid $lightRed;
        }

        &.isActive {
          border: 1px solid $lightRed;
          box-shadow: inset 0px 1px 9px 2px $red;
        }

        span {
          font-size: 12px;
        }
      }
    }
  }

  @include media($until: $sm) {
    min-width: 100vw;
    height: calc(100vh - 138px);
    max-height: unset;
  }

  @include media($until: $md) {
    max-height: unset;
  }

  .title,
  .show_more {
    font-size: 12px;
  }

  .show_more {
    color: $mainOrange;
    cursor: pointer;

    &:hover {
      color: $lightOrange;
    }
  }

  .bullet {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: $white;
    margin: 0 6px;
  }
}

.error {
  height: 4px;
  font-size: 0.8rem !important;
  text-indent: 15px;
  color: $red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 0 0;
}
.loading {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  font-size: 12px;
  opacity: 0.8;
  min-height: 220px;
}

.volk_deposit_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  img {
    max-width: 120px;
    @include media($until: $md) {
      max-height: 100px;
    }
  }
  button {
    background-color: #0b72b1;
    margin: 3px;
  }
}
