@use 'styles/theme.module' as *;

.main_content {
  min-width: 320px;
  height: calc(100vh - 100px);
  max-height: calc(100vh - 150px);
  overflow: hidden auto;
  @include media($until: $md) {
    max-height: calc(100vh - 100px);
  }
  form {
    margin-bottom: 20px;
    :global(.MuiStack-root) {
      overflow: visible;
    }
  }
  .main_title {
    text-transform: uppercase;
    margin: 0 0 24px 0;
    color: $lightRed;
    padding-left: 12px;
  }
  .input_content {
    display: grid;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    @include media($until: $lg) {
      grid-template-columns: repeat(1, 1fr);
    }
    @include media($until: $md) {
      width: 100vw;
      padding: 0 20px;
    }
    margin: 0 auto;
    .form_control {
      background-color: #3d4760;
      border-radius: 10px;
      display: flex;
      height: 50px;
      justify-content: center;
      margin: 10px 0;
      padding: 12px;
      width: 100%;
      p {
        color: $error;
        font-weight: 600;
        background-color: $secondaryBlue;
        border-radius: 10px;
        padding: 0 5px;
      }
      .datePicker_box {
        height: auto;
        display: flex;
        margin-top: 0px !important;
        align-items: center;
        justify-content: space-between;

        :global(.MuiInputAdornment-root) {
          svg {
            color: $white;
          }
        }
      }
      .custom_input {
        position: relative;
        border-radius: 5px;
        margin-top: 0;
        input {
          text-indent: 5px;
        }
        &:before {
          border-bottom: none !important;
        }
        &:after {
          content: '';
          border: none;
          border-radius: 5px;
        }
      }
      fieldset {
        border: none;
      }

      &.is_disabled {
        label {
          color: grey;
        }
      }
    }
    .is_error {
      border: 1px solid $error;
    }
    .is_disabled {
      color: grey;
    }

    .left_side {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
    }
  }
}
