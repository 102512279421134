@use 'styles/theme.module' as *;

.content {
  display: flex;
  justify-content: center;
  min-height: 600px;
  width: 100%;
  .desktop {
    max-width: 1300px;
    width: 100%;
    padding: 0 20px;

    @include media($until: $md) {
      display: none;
    }
  }
  .mobile {
    width: 100%;
    padding: 20px;

    @include media($from: $md) {
      display: none;
    }
  }
}
