@use 'styles/theme.module' as *;
.main {
  display: flex;
  .right_side {
    flex-basis: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .games {
      position: relative;
      height: auto;
      padding: 12px 0;
      display: inline-grid;
      grid-template-columns: repeat(6, 1fr);
      min-height: 300px;
      gap: 18px;
      .gameItem {
        height: fit-content;
        margin: 0;
        gap: 10px;
        img {
          max-width: 250px !important;
        }
      }
      @include media($until: $sm) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 381px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include media($from: $sm, $until: 1574) {
        grid-area: 1/1;
        grid-template-columns: repeat(4, 1fr);
      }
      @include media($from: $md, $until: $lg) {
        grid-area: 1/1;
        grid-template-columns: repeat(4, 1fr);
      }
      @include media($from: $xl) {
        grid-area: 1/1;
        grid-template-columns: repeat(8, 1fr);
      }
      &.loader {
        display: flex;
        justify-content: center;
        width: 100%;
      }
      &.noResults {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
    .showMore {
      margin: 24px;
      width: 80%;
      button {
        color: $white;
        background-color: rgba(243, 246, 253, 0.085);
        &:hover {
          background-color: rgba(155, 162, 175, 0.05);
        }
      }
    }
  }
  .left_side {
    padding: 20px;
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .category_list {
      width: 100%;
      border-radius: 10px;
      padding: 12px;
      margin-bottom: 24px;
      min-height: 200px;
      background-color: #262d4440;
      display: grid;
      overflow: auto;
      grid-template-columns: repeat(4, 1fr);
      height: auto;

      @include media($until: $md) {
        grid-template-columns: repeat(3, 1fr);
      }
      span.category_item {
        border-radius: 10px;
        width: auto;
        max-width: 134px;
        padding: 8px;
        background-color: $secondaryBlueMobile;
        margin: 3px;
        cursor: pointer;
        &:hover,
        &.active {
          background-color: #262d4440;
          background-image: linear-gradient($lightRed 0%, $mainOrange 80%);
        }
      }

      .category_logo {
        transition: all 0.4s ease-out;
        padding: 1px 8px;
        display: flex;
        flex-direction: column;
        background-color: #262d4440;
        margin: 5px;
        align-content: center;
        justify-content: center;
        cursor: pointer;
        min-height: 85px;
        max-width: 97px;
        min-width: 97px;
        height: auto;
        width: fit-content;
        &:hover,
        &.active {
          background-color: #b9c1db73;
          box-shadow: 0px 8px 6px 0px rgba(207, 213, 233, 0.25);
        }
        img {
          width: 28px;
          margin: 3px auto;
        }
        p {
          font-size: 0.6rem;
          margin: 3px auto;
          min-height: 26px;
        }
        @include media($until: $md) {
          min-width: 75px;
        }
      }
      @include media($until: $md) {
        min-height: 80px;
        overflow: auto;
      }
    }

    .provider_list {
      padding: 12px;
      margin-bottom: 24px;
      min-width: 100%;
      .providers {
        display: inline-block;
        height: 700px;
        overflow: auto;
        will-change: scroll-position;
        scrollbar-color: rgba(67, 75, 83, 0.1) transparent;
        scrollbar-width: thin;
        .logo {
          margin: 2px;
          display: inline;
          cursor: pointer;
          padding: 0 8px;
          background-color: #262d4473;
          transition: all 0.4s ease-out;
          border: 0;
          box-shadow: none;
          img {
            width: 70px;
            margin: 12px;
            max-height: 45px;
            height: auto;
            object-fit: cover;
            object-position: center;
          }
          &:hover,
          &.active {
            padding: 1px 8px;
            background-color: #b9c1db73;
            box-shadow: 1px -9px 6px 0px rgba(207, 213, 233, 0.25);
          }
          @include media($from: $lg) {
            img {
              width: 100px;
            }
          }
        }
        @include media($until: $md) {
          display: block;
          height: 140px;
          overflow: hidden auto;
          padding: 0;
        }
      }
    }
    @include media($until: $md) {
      padding: 20px 10px;
    }
  }

  @include media($until: $md) {
    flex-direction: column;
  }
}

.vertical_carousel {
  width: 72px;
  height: 349px;
  background-color: $secondaryBlueMobile;
}

.category_section {
  padding: 4px;
  .category_show_all {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  @include media($until: $sm) {
    padding: 12px 4px;
  }
  @include media($from: $sm, $until: $md) {
    padding: 12px 14px;
  }
  .category_carousel {
    width: calc(100% - 93px);
    transform: translateX(125px);
    @include media($until: $sm) {
      width: 99%;
      transform: translateX(3px);
    }
    @include media($from: $sm, $until: $md) {
      width: calc(100% - 114px);
      transform: translateX(125px);
    }
  }
}

.link,
.link_provider {
  flex-basis: 90%;
  display: flex;
  align-items: center;
  padding-left: 12px;
  img,
  svg {
    width: 24px;
    height: 24px;
  }
  a {
    color: $white;
    font-weight: 400;
    &:hover {
      color: $mainOrange;

      img,
      svg {
        filter: invert(72%) sepia(59%) saturate(3061%) hue-rotate(10deg) brightness(91%)
          contrast(101%);
      }
    }
  }
}

.icon_menu {
  display: flex;
  align-items: center;

  button {
    margin-right: 8px;
    background-color: #262d4440;
    border-radius: 10px;
    padding: 3px;
    svg {
      fill: $white;
      font-size: 2.2rem;
    }
    &:hover,
    &.active {
      background-image: linear-gradient($lightRed 0%, $mainOrange 80%);
    }
  }
}
