@use 'styles/theme.module' as *;

.main_area {
  padding: 0 12px;
  height: calc(100vh - 100px);
  overflow: hidden auto;
  @include media($until: $sm) {
    width: 100vw;
  }
  @include media($until: $xsm) {
    height: calc(100vh - 138px);
  }
  button {
    color: $white;
  }
  .main_title {
    text-transform: uppercase;
    margin: 0 0 12px 0;
    color: $lightRed;
    display: flex;
    align-items: center;
    img {
      width: 24px;
      margin: 0 4px;
    }
  }
  .balance_block {
    background-color: #161d31;
    border-radius: 15px;
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    @include media($until: $xsm) {
      padding: 20px 6px;
    }

    .balance_types_block {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 10px 0;
      width: 100%;

      @include media($until: $xsm) {
        img {
          height: 14px;
        }
      }
      .middle_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        @include media($until: $xsm) {
          width: 12px;
        }
      }

      .balance_item {
        display: flex;
        flex-direction: column;
        background-color: #3d4760;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        flex-basis: 30%;
        max-width: 174px;
        height: 94px;
        padding: 4px;
        cursor: pointer;
        span {
          font-size: 12px;
          text-align: center;
          max-width: 120px;
        }
        img {
          width: auto;
          max-width: 90px;
          height: 30px;
        }
        &:hover {
          background-color: #545f78dd;
        }
        &.isActive {
          box-shadow: inset 0px 1px 9px 2px $red;
        }
        @include media($until: $xss) {
          width: auto;
          min-width: 86px;
          height: 72px;

          img {
            height: 20px;
          }
          span {
            font-size: 8px;
            text-align: center;
            width: 92px;
          }
        }
        @include media($from: 431, $until: 628) {
          min-width: 85px;
        }
      }
      .loading {
        display: grid;
        grid-template-columns: 1fr;
        margin: 0 auto;
        font-size: 12px;
        opacity: 0.8;
      }
    }
  }

  .amount_block {
    background-color: #3d4760;
    border-radius: 15px;
    margin: 20px 0;

    .promo {
      margin: 10px 0;
      background-color: #3d4760;
      height: 68px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      .currency_name {
        text-transform: uppercase;
        padding: 9px;
        background-color: $mainOrangeOld;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        transform: translateX(-3px);
      }
    }
  }
  .dragged_content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    .maket {
      display: flex;
      opacity: 0.3;
      flex-direction: column;
      background-color: #3d4760;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      flex-basis: 40%;
      max-width: 250px;
      height: 110px;
      padding: 4px;
      cursor: pointer;
      border: 2px dotted $white;
      span {
        font-size: 1.2rem;
        text-transform: uppercase;
      }
      &:hover {
        background-color: #8894b1dd;
      }
      &.isActive {
        box-shadow: inset 0px 1px 9px 2px $red;
      }
      &.isdropped {
        opacity: 1;
      }
      .balance_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: fit-content;
        height: 80px;
        padding: 4px;
        cursor: pointer;
        transform: scale(1.4);

        span {
          font-size: 0.6rem;
          text-align: center;
          max-width: 142px;
           @include media($until: $xsm) {
           max-width: 102px;
        }
        }
        img {
          width: auto;
          max-width: 90px;
          height: 30px;
        }

        @include media($until: $xss) {
          height: 60px;
          transform: scale(0.9);
        }
      }

      @include media($until: $xss) {
        width: 150px;
        height: 85px;

        img {
          height: 20px;
        }
      }
    }
  }
}

.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  opacity: 0.3;
  .divider_part {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .divider {
      width: 25%;
      min-width: 130px;
      height: 4px;
      border-bottom: 1px solid $white;
      @include media($until: $xsm) {
        min-width: 100px;
      }
    }
    .or_text {
      margin: 6px;
      font-size: 12px;
      text-align: center;

      @include media($until: $xsm) {
        font-size: 10px;
        width: 60px;
      }
    }
  }
}
