@use 'styles/theme.module' as *;

.container {
  min-height: 500px;
  margin: 30px auto;
  .title {
    background-image: linear-gradient($lightRed 0%, $mainOrange 80%);
    background-clip: text;
    width: 100%;
    padding: 12px 24px;
    p {
      margin: 0 14px;
      color: transparent;
      font-size: 1.4rem;
      font-weight: 800;
    }
  }

  .leftSide {
    flex-basis: 20%;
    min-width: 200px;
    max-width: 230px;
    .modal_level {
      width: 213px;
      border-radius: 20px;
      margin: 16px 8px;

      @include media($until: $md) {
        display: none;
      }
    }
    .actions {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
      button {
        margin: 0;
      }
    }
  }
  .rightSide {
    flex-basis: 80%;
    padding: 0.4em 0.8em;
    :global(.MuiTabPanel-root) {
      padding: 12px 0;
    }
    &.isMobile {
      padding: 0;
      :global(.MuiTabPanel-root) {
        padding: 0;
      }
      :global(.MuiTab-root) {
        font-size: 0.7rem;
      }
    }
  }

  .rounded {
    :global(.Mui-selected) {
      color: $white;
      background-image: linear-gradient($lightRed 0%, $mainOrange 80%);
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }
}
