@use 'styles/theme.module' as *;

.captcha {
  display: flex;
  flex-direction: column;
  background-color: rgb(47 50 64);
  min-width: 260px;
  padding: 10px;
  margin-top: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  p,
  h4 {
    font-size: 13px;
  }
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
     button{
      margin: 3px;
     }
  }
}
