@use 'styles/theme.module' as *;

body {
  background-color: $mainBlue !important;
  padding-right: 0 !important;
  overflow: auto !important;
  overflow-x: hidden !important;
  background-image: url(../public/pngegg.webp);
}

#root {
  height: 100vh;
}

.App {
  background-color: rgb(13 19 28 / 31%);
  // background-color: $mainBlue;

  header {
    height: 73px;
    border-radius: 0;
    @include media($until: $sm) {
      padding: 6px 0 !important;
    }
  }
  .divider {
    width: 95%;
    margin: auto;
    position: sticky;
    top: 73px;
    border: 0;
    border-top: 1px solid #353841;
    height: 0;
    z-index: 11;
  }
  .footer {
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: $mainBlue;
    @include media($until: $md) {
      bottom: 70px;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-container {
  position: relative;
  top: 76px;
  min-height: 100%;
  color: $white;
  max-width: 2048px;
  margin:  0 auto;
}

.App-btn,
.red,
.orange {
  margin-top: 6px;
  font-weight: 600;
  color: $white;
  border-color: $mainOrange;
  text-transform: capitalize;
  border-radius: 10px;
  @include btnRed;
  &:hover {
    @include btnRedHover;
  }
}

.orange {
  @include btnOrange;
  &:hover {
    @include btnOrangeHover;
  }
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  -webkit-touch-callout: none !important;
  // -webkit-user-select: none !important;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
}
