@use 'styles/theme.module' as *;

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 200px;
  padding: 12px;
  .action {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .playing_btn,
    .close_btn {
      margin: 6px;
      font-weight: 600;
      color: $white;
      background: $mainOrange;
      border-color: $mainOrange;
      text-transform: capitalize;
      @include btnRed;
     &:hover {
      @include btnRedHover;
    }
    }
  }
}
