@use 'styles/theme.module' as *;

.icon {
  position: absolute !important;
  top: 4px;
  right: 2px;
  svg {
    color: $white;
  }
}

:global(.MuiIconButton-root):hover {
  background-color: transparent !important;
}
