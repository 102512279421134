@use 'styles/theme.module' as *;

.content {
  display: flex;
  flex-direction: column;
  min-height: 600px;
  width: 100%;
  font-size: 0.8rem;
  max-width: 1680px;
  margin: 40px auto;

  @include media($until: $md) {
    margin: 10px auto;
  }
  .attention {
    h1 {
      color: $mainOrange;
      width: 95%;
      margin: 0 auto;
      font-size: 1.3rem;
    }
  }
  h1 {
    font-size: 1.6rem;
    font-weight: 800;
    text-align: center;
  }
  .body,
  .req_body {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .col_left,
    .col_right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-basis: 100%;
      padding: 12px 18px;
      .title {
        margin: 12px 4px;
      }
      .requirements {
        margin: 0 0 12px;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
    .col_right {
      align-items: center;
      width: 100%;
    }
    .col_left {
      @include media($from: $sm) {
        padding: 12px 40px;
      }
    }
    .card {
      width: 100%;
      max-width: 500px;
      height: auto;
      min-height: 234px;
      margin: 10px auto 25px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .card_number {
        background-color: $greyBGLevel;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: 460px;
        flex-direction: column;
        max-width: 440px;
        .row_title_block {
          display: flex;
          justify-content: space-between;
          align-items: center;

          width: 86%;
        }
        .row {
          display: flex;
          margin: 6px 0;
          flex-direction: column;
          width: 100%;
          align-items: center;
          span {
            color: #ffffff9c;
            width: 95%;
            font-size: 0.8rem;
            padding: 4px;
          }
          h6 {
            font-size: 0.8rem;
            text-align: left;
            width: 86%;
          }
          h3 {
            color: #ffffff;
            font-size: 1rem;
            border: #838383 solid thin;
            border-radius: 5px;
            padding: 4px 12px;
            width: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 3px;
          }
        }
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      .red,
      .green.grey {
        margin: 12px;
      }
      .red {
        background-color: $mainOrange;
      }
      .green {
        background-color: $darkGreen;
      }
      .grey {
        background-color: $greyBGLevel;
      }
    }
    @include media($until: $lg) {
      flex-direction: column-reverse;
    }
  }
  .req_body {
    h1 {
      color: $warning;
       margin-bottom: 10px;
    }
    p {
      width: 80%;
      margin: 0 auto;
       text-align: center;
    }
    flex-direction: column;
    max-width: 1500px;
    padding: 20px;
    .actions {
      margin-top: 20px;
      .grey{
        max-width: 280px;
      }
    }
  }
}
