@use 'styles/theme.module' as *;

.main {
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  padding: 14px 75px;
  max-width: 1300px;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: auto;
    background: $greyBGGradient;
    border-radius: 18px;
    max-width: 778px;
    margin: 0 auto;
    .footer {
      display: flex;
      justify-content: center;
      background-color: #262732;
      padding: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      .btn {
        @include btnRed;
        &:hover {
          @include btnRedHover;
        }
      }
    }
  }

  @include media($until: $md) {
    padding: 12px 20px;
  }
  @include media($until: $sm) {
    padding: 12px;
  }

  .anounce__freespins {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    background: $greyBGGradient;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    overflow: hidden;
  }

  .anoounce__left {
    padding: 24px;
    position: relative;
    z-index: 2;
    color: $white;
    width: 100%;
    @include media($until: $xsm) {
      padding: 12px;
    }
  }

  .anounce__title {
    margin-bottom: 15px;
    max-width: 450px;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    color: $white;
    text-indent: 20px;
  }

  .anounce__highlight,
  .anounce__highlight_small {
    color: $yellow;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 7px;
    @include media($until: $xsm) {
      font-size: 1.2rem;
    }
  }
  .anounce__highlight_small {
    font-size: 13px;
    text-indent: 40px;
    @include media($until: $xsm) {
      font-size: 11px;
      text-indent: 0;
      white-space: unset;
    }
  }

  .anounce__highlight_icon {
    width: 30px;
  }

  .anounce__description {
    max-width: 450px;
    font-weight: 400;
    font-size: 13px;
    line-height: 155%;
    display: flex;
    align-items: center;
    ul {
      @include media($until: $xsm) {
        padding-left: 16px;
      }
    }
  }

  .anounce__image {
    position: absolute;
    right: 0;
    left: calc(60%);
    height: 100%;
    background: url('../../assets/aboutUs/aboutUs.png') no-repeat;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    background-size: calc(90%);
    z-index: 1;
    background-position: left calc(60%);
    @include media($until: $xsm) {
      background-position: left calc(10%);
      background-size: calc(80%);
      left: calc(70%);
    }
    @include media($until: $xss) {
      background-position: calc(68%) calc(9%);
      background-size: calc(80%);
      //  left: calc(70%);
    }
  }

  .arrowed_list {
    li {
      list-style-type: none;
      &:before {
        content: ' \2192';
        margin-right: 10px;
        font-size: 11px;
        color: $warning;
        font-weight: 700;
      }
    }
  }
}
