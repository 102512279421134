@use 'styles/theme.module' as *;

.dialog {
  --bg-win: rgba(137, 43, 226, 0.5);
  --text-win: rgba(255, 191, 0);
  --font: Impact, sans-serif;
  text-align: center;

  @include media($until: 500) {
    padding: 20px !important;
    width: 100vw;
    min-width: unset;
  }

  :global(.MuiPaper-root) {
    margin: 12px;
    border-radius: 18px;
    padding: 10px !important;
    overflow: unset;
    min-width: 300px;
    @include media($until: 500) {
      min-width: unset;
    }
  }

  :global(.MuiDialogContent-root) {
    overflow: unset;
  }

  &.isWin {
    background-color: var(--bg-win) !important;
    overflow-y: auto;
    height: 100vh;

    :global(.MuiPaper-root) {
      background-color: transparent;
      box-shadow: none;

      @include media($until: 500) {
        margin: 0 !important;
      }
    }
  }

  .btn {
    justify-content: center;

    button {
      z-index: 100005 !important;
      margin-top: 6px;
      text-transform: uppercase;
      font-weight: 600;
      color: $white;
      border-color: $mainOrange;
      border-radius: 10px;
      @include btnRed;

      &:hover {
        @include btnRedHover;
      }
    }
  }

  .win_title,
  .win_context {
    min-width: 300px;
    font-size: 3rem !important;
    font-family: var(--font);
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: var(--text-win);
      font-size: 3rem !important;
      font-weight: bolder;
    }

    .bonus {
      span {
        font-size: 3rem !important;
        font-weight: bolder;
      }

      font-weight: bolder;
      font-family: var(--font);
      font-size: 4rem !important;
      color: var(--text-win);

      @include media($until: 500) {
        font-size: 3rem !important;
      }
    }

    @include media($until: 500) {
      min-width: 150px;
      font-size: 2rem !important;

      p {
        font-size: 2rem !important;
      }
    }
  }
  a {
    text-decoration: none;
    color: $white;
    font-size: 0.8rem;
    background-color: $mainLightGrey;
    padding: 4px 12px;
    border-radius: 8px;
    height: 32px;
    display: flex;
    align-items: center;
  }
  .links_btn {
    display: flex;
    padding: 0 40px;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    a.btn_tg {
      margin: 6px;
      display: flex;
      background-color: #2481cc;
    }
    @include media($until: $md) {
      flex-direction: column;
      padding: 0;
    }
  }
}