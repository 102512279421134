@use 'styles/theme.module' as *;

.main_area {
  padding: 0 12px;
  min-width: 550px;
  height: calc(100vh - 100px);
  max-height: calc(100vh - 150px);
  overflow: hidden auto;

  button {
    color: $white;
  }

  .main_title {
    text-transform: uppercase;
    margin: 0 0 12px 0;
    color: $lightRed;
  }

  .promo_block {
    background-color: $BgGrey;
    border-radius: 15px;
    padding: 12px;
    margin-bottom: 12px;

    .promo {
      margin: 10px 0;
      height: 68px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      p {
        height: 0;
      }
      @include media($until: $sm) {
        height: auto;
        flex-direction: column;
        align-items: flex-end;

        button {
          margin-top: 10px;
        }
      }
    }
  }

  @include media($until: $sm) {
    min-width: 100vw;
    height: calc(100vh - 138px);
    max-height: unset;
  }

  @include media($until: $md) {
    max-height: unset;
  }

  .title {
    font-size: 12px;
  }
}

.error {
  height: 20px;
  font-size: 0.8rem;
  text-indent: 15px;
  color: $red;
}
