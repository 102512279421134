@use 'styles/theme.module' as *;

.container {
  display: flex;
  align-items: baseline;
  min-height: 500px;
  min-width: 50vw;
  max-height: 780px;

  .leftSide {
    flex-basis: 20%;
    min-width: 200px;
    max-width: 230px;

    .tabs {
      margin-top: 20px;
      @include media($until: $xsm) {
      width: 100vw;
      }
    }

    .modal_level {
      width: 213px;
      border-radius: 20px;

      @include media($until: $md) {
        display: none;
      }
    }

    .menu_list {
      width: 100%;
      list-style: none;
      padding: 2em;
      color: #6d6e6f;
      cursor: pointer;
    }

    .actions {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;

      button {
        margin: 0;
      }
    }

    .menu_list li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: solid 2px #edeff1;
      border-radius: 0.2em;
      padding: 0.4em 0.8em;
      margin-bottom: 1em;

      &:hover {
        border: solid 2px #b0bec5;
      }
    }

    .menu_list p {
      max-width: none;
      font-weight: bold;
      margin: 0;
    }

    &.isMobile {
      flex-basis: 100%;
      max-width: unset;
      padding: 4px;
    }
  }

  .rightSide {
    flex-basis: 90%;
    padding: 0.4em 0.8em;

    :global(.MuiTabPanel-root) {
      padding: 12px 0;
    }

    &.isMobile {
      padding: 0;

      :global(.MuiTabPanel-root) {
        padding: 0;
      }

      :global(.MuiTab-root) {
        font-size: 0.7rem;
      }
    }

    @include media($from: 1100) {
      min-width: 800px;
    }

    @include media($from: $sm, $until: $md) {
      min-width: 711px;
    }
  }

  &.isMobile {
    flex-direction: column;
    align-items: center;

    :global(.MuiTab-root) {
      font-size: 0.8rem;
    }

    :global(.MuiTabs-indicator) {
      display: none;
    }
  }

  @include media($from: $md) {
    max-height: 900px;
    width: calc(100vw - 100px);
    max-width: 1024px;
    height: calc(100vh - 100px);
  }
}