@use 'styles/theme.module' as *;

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 68vw;
  margin: 0 auto;
  max-width: 1300px;
.btn{
  margin-top: 6px;
  font-size: 0.6rem;
    padding: 4px;
    @include btnGreen;
      &:hover {
        @include btnGreenHover;
      }
}
}

 