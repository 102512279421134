@use 'styles/theme.module' as *;

.currencies {
  border: 1px solid white;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  height: 41px;
  justify-content: space-around;
  align-items: center;
  padding: 0 0 0 6px;
  .svg {
    margin-right: 3px;
  }
  .content {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
