@use 'styles/theme.module' as *;

.general_area {
  padding: 0 10px;
  @include media($until: $md) {
    max-height: 100vh;
    min-width: 100vw;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    @include media($until: $xss) {
      width: 96vw;
    }
    .content_list {
      padding: 12px 0;
      height: calc(100vh - 200px);
      max-height: 800px;
      width: 100%;
      overflow: hidden auto;
    }
    .item_list,
    .item_list_title {
      box-sizing: border-box;
      display: flex;
      align-content: center;
      width: 99%;
      justify-content: space-evenly;
      padding: 5px 5px;

      .item {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        align-self: center;
        .name {
          font-size: 0.7rem;
          @include media($until: $sm) {
            font-size: 0.5rem;
          }
        }
      }
      .amount {
        font-size: 0.7rem;

        @include media($until: $sm) {
          font-size: 0.5rem;
        }
        color: $lightOrange;
        text-transform: uppercase;
      }
      .wallet {
        font-size: 0.7rem;

        @include media($until: $sm) {
          font-size: 0.5rem;
        }
        color: $lightOrange;
        text-transform: uppercase;
      }
      span {
        width: 100px;
        text-align: center;
        align-self: center;
        line-height: 1.2;
        @include media($until: $sm) {
          width: 80px;
        }
      }
      .play_game_box {
        width: auto;
        @include media($until: $sm) {
          width: 80px;
        }
      }
    }
    .item_list_title {
      background-image: linear-gradient($mainOrange 10%, $lightRed 80%);
      border-radius: 10px;
      justify-content: space-evenly;
      &.isMobile {
        background-image: repeating-linear-gradient(0deg, #ff572200, #ff5722b8 100px);
      }
    }
  }
  .item_list {
    .item {
      span {
        @include media($until: $xss) {
          width: 60px;
        }
      }
    }
  }
}

.divider {
  margin: 3px auto;
  height: 0.5px;
  width: 88%;
  background: $lightOrange;
}
