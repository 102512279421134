@use 'styles/theme.module' as *;

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 600px;
  width: 100%;
  font-size: 0.8rem;
  span {
    font-size: 1rem;
  }
  .item {
    width: 100%;
    background: $secondaryBlueMobile;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 10px;
    .header {
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.5px solid $lightOrange;
      .img {
        img {
          width: 45px;
        }
        height: 70px;
        flex-basis: 50%;
      }
      .btn,
      .btn_out {
        flex-basis: 50%;
        display: flex;
        justify-content: flex-end;
        button {
          font-size: 0.7rem;
          background-color: $mainOrange;
        }
      }
      .btn{
         button {
          background-color: $darkGreen;
         }
      }
      .btn_out {
        flex-basis: 100%;
      }
    }
    .body {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .col_left,
      .col_right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 12px;
      }
      .col_left {
        p {
          margin-top: 4px;
        }
      }
    }
    p {
      font-size: 0.8rem;
    }
  }
}
