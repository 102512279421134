@use 'styles/theme.module' as *;

.winners_block {
  display: flex;
  align-items: center;

  p {
    font-size: 0.6rem;
    text-align: center;
    width: 100%;
  }

  .divider {
    margin: 3px auto;
    height: 0.6px;
    width: 75%;
    background: $mainOrange;
  }
  .amount {
    font-size: 0.6rem;
    color: $mainOrange;
  }
}

.block_title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem !important;
  font-weight: 900;
  color: $white;
  background-image: linear-gradient($mainOrange 0%, $mainOrange 60%);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  text-transform: uppercase;
}
.sliderWrapperVertical {
  height: 296px;

  :global(.swiper-wrapper) {
    // top: 28px;
    :global(.swiper-slide) {
      height: 70px;
      display: flex;
      justify-content: center;
    }
  }
  :global(.swiper-scrollbar) {
    display: none;
  }
  &.special_height {
    height: 90px;
    padding: 0 12px;
  }
}

.tab_btn {
  padding: 0 !important;
  .svg {
    color: $white;
    filter: invert(59%) sepia(0%) saturate(50%) hue-rotate(269deg) brightness(100%) contrast(4);
  }
  &:hover {
    .svg {
      filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(340deg) brightness(119%)
        contrast(119%);
    }
  }
  &:global(.Mui-selected) {
    .svg {
      color: $mainOrange;
      filter: invert(22%) sepia(60%) saturate(1352%) hue-rotate(-15deg) brightness(119%)
        contrast(119%);
    }
    span {
      color: $mainOrange;
    }
  }
}
