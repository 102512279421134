@use 'styles/theme.module' as *;

:root {
  --duration: 6.66s;
}

.card_front:before {
  content: '';
  inset: 0;
  position: absolute;
  transform: translate3d(0, 0, 0.01px);
  mix-blend-mode: color-dodge;
  filter: brightness(2) contrast(0.8);

  animation: bg var(--duration) ease infinite;
}

.card_front {
  display: grid;
  position: relative;
  transform: translate3d(0, 0, 0.01px);
  width: 90vw;
  max-width: 400px;
  aspect-ratio: 3/2;
  border-radius: 3.5% 3.5% 3.5% 3.5% / 5% 5% 5% 5%;
  background-image: url('../../../../assets/payment/card.webp');
  background-size: cover;
  box-shadow:
    0 30px 40px -25px rgba(15, 5, 20, 1),
    0 20px 50px -15px rgba(15, 5, 20, 1);
  overflow: hidden;
  animation: tilt var(--duration) ease infinite;
  image-rendering: optimizequality;
}

.card_front:after {
  content: '';
  background: none, none,
    linear-gradient(
      125deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 0.1%,
      rgba(255, 255, 255, 0) 60%
    );
  background-size: 200% 200%;
  mix-blend-mode: hard-light;
  animation: bg var(--duration) ease infinite;
  border-radius: 3.5% 3.5% 3.5% 3.5% / 5% 5% 5% 5%;
}

.card_front * {
  font-family:
    PT Mono,
    monospace;
}

.cardLogo,
.expiry,
.name,
.number,
.chip,
.icon {
  color: #ccc;
  position: absolute;
  margin: 0;
  padding: 0;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  font-size: clamp(0.75rem, 2.8vw + 0.2rem, 1.1rem);
  inset: 5%;
  text-shadow:
    -1px -1px 0px rgba(255, 255, 255, 0.5),
    1px -1px 0px rgba(255, 255, 255, 0.5),
    1px 1px 0px rgba(0, 0, 0, 0.5),
    1px -1px 0px rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.name,
.number,
.expiry {
  background-image: linear-gradient(to bottom, #ededed 20%, #bababa 70%), none,
    linear-gradient(120deg, transparent 10%, white 40%, white 60%, transparent 90%);
  background-size: cover, cover, 200%;
  background-position: 50% 50%;
  background-blend-mode: overlay;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: bg var(--duration) ease infinite;
}

.number {
  font-family:
    PT Mono,
    monospace;
  text-align: center;
  font-size: clamp(1rem, 8vw - 0.5rem, 1.5rem);
  letter-spacing: 0.025em;
  top: 60%;
  bottom: auto;
  display: flex;
    justify-content: center;
    align-items: center;
  .copy_icon {
    margin-left: 8px;
    font-size: 28px;
    cursor: pointer;
    color: $mainOrange;
    background-color: #303545;
    padding: 4px;
    border-radius: 8px;
  }
}
.expiry,
.name {
  top: auto;
}

.name {
  right: auto;
  max-width: 180px;
  line-height: 1.2;
  text-align: left;
}

.expiry {
  left: auto;
}

.cardLogo {
  bottom: auto;
  left: auto;
  width: 15%;
  // filter: invert(1) saturate(0) brightness(1) contrast(1.2);
  mix-blend-mode: screen;
}

.chip {
  display: grid;
  place-items: center;
  width: 14%;
  aspect-ratio: 5/4;
  left: 10%;
  top: 30%;
  border-radius: 10% 10% 10% 10% / 15% 15% 15% 15%;

  background-image: none, none, linear-gradient(120deg, #777 10%, #ddd 40%, #ddd 60%, #777 90%);
  background-size: 200% 200%;
  background-position: 50% 50%;

  overflow: hidden;
  animation: bg var(--duration) ease infinite;
}

.chip svg {
  display: block;
  width: 90%;
  fill: none;
  stroke: #444;
  stroke-width: 2;
}

.contactless {
  position: absolute;
  left: 23%;
  top: 30%;
  width: 12%;
  rotate: 90deg;

  stroke-width: 1.25;
  stroke: currentColor;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  opacity: 0.5;
}

.icon {
  width: 25%;
  bottom: auto;
  right: auto;
  top: 0;
  left: 15px;
  filter: invert(1) hue-rotate(180deg) saturate(5) contrast(2);
}

@keyframes tilt {
  0%,
  100% {
    transform: translate3d(0, 0, 0.01px) rotateY(-20deg) rotateX(5deg);
  }
  50% {
    transform: translate3d(0, 0, 0.01px) rotateY(20deg) rotateX(5deg);
  }
}

@keyframes bg {
  0%,
  100% {
    background-position:
      50% 50%,
      calc(50% + 1px) calc(50% + 1px),
      0% 50%;
  }
  50% {
    background-position:
      50% 50%,
      calc(50% - 1px) calc(50% - 1px),
      100% 50%;
  }
}

main {
  place-items: center;
  min-height: 100%;
  perspective: 1000px;
}

#chip_svg,
#contactless {
  display: none;
}
.chip_svg {
  display: none;
}
