@use 'styles/theme.module' as *;

.notifications_item {
  position: relative;
  align-items: center;
  background-color: rgb(118 124 148 / 12%);
  justify-content: space-between;
  align-items: center;
  border-radius: 0.3em;
  padding: 0.4em 0.8em;
  margin-bottom: 1em;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 0.8rem;
      color: $mainLightGrey;
    }
    .title {
      p{
      color: $mainOrange;
      }
    }
  }
  .text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      background-color: $mainOrange;
      font-size: 0.6rem;
      color: $white;
      margin: 4px 0;
      padding: 8px;
      min-width: 97px;
    }
    p {
      font-size: 0.8rem;
    }
  }
  .new,
  .read {
    margin: 6px 0;
    background-color: #0d131c9c;
    width: auto;
    padding: 6px 8px;
    border-radius: 0.2em;
    display: flex;
    justify-content: center;
    color: $white;
    font-size: 0.7rem;
    align-items: center;
  }
  .new {
    background-color: $success;
  }
}
