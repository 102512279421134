@use 'styles/theme.module' as *;

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 600px;
  width: 100%;
  margin-top: -1px;

  .item_list,
  .item_list_title {
    box-sizing: border-box;
    display: flex;
    align-content: center;
    width: 99%;
    justify-content: space-evenly;
    padding: 10px 5px;
    .item {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .name {
        font-size: 0.8rem;
      }
    }
    .amount {
      font-size: 0.8rem;
      color: $mainOrange;
      text-transform: uppercase;
    }
    span {
      width: 120px;
      text-align: center;
      @include media($until: 713) {
        width: 100px;
        min-width: 86px;
      }
    }
  }
  .item_list_title {
    background-image: linear-gradient($mainOrange 10%, $lightRed 80%);
    justify-content: space-evenly;
    &.isMobile {
      background-image: repeating-linear-gradient(0deg, #ff572200, #ff5722b8 100px);
    }
  }
}

.divider {
  margin: 3px auto;
  height: 0.5px;
  width: 88%;
  background: $mainOrange;
}
