@use 'styles/theme.module' as *;

.content {
  padding: 24px;
  height: 100%;
  width: 90%;
  margin: 0 auto;
  @include media($until: $sm) {
    padding: 20px;
    width: 100%;
  }
  .main_info {
    background-image: url('../../../assets/payment/paymentBanner.webp');
    background-size: cover;
    margin: 0 auto;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    height: 300px;
    border-radius: 16px;
    background-position: right;
    margin-bottom: 40px;

    .titles_info {
      align-self: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-left: 30px;
      @include media($until: $sm) {
        padding-left: 10px;
      }

      a {
        width: 150px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        background-color: #5b1cfc;
        border-radius: 10px;
        border: 4px solid #6e38fc;
        text-transform: unset;
        cursor: pointer;
      }
    }
  }
  .desktop {
    width: 100%;
    @include media($until: $md) {
      display: none;
    }
  }
  .mobile {
    width: 100%;
    @include media($from: $md) {
      display: none;
    }
  }
}
