@use 'styles/theme.module' as *;

.sliderWrapper {
  :global(.swiper-button-next),
  :global(.swiper-button-prev) {
    background: $mainOrange;
    width: 65px;
    height: 65px;
    border-radius: 50px;
    opacity: 0.8;
    @include media($until: $sm) {
      width: 38px;
      height: 38px;
    }
  }
  :global(.swiper-button-next):after,
  :global(.swiper-button-prev):after {
    content: 'next';
    color: $white;
    font-size: 24px;
    @include media($until: $sm) {
      font-size: 16px;
    }
  }
  :global(.swiper-button-next.swiper-button-disabled),
  :global(.swiper-button-prev.swiper-button-disabled) {
    display: none;
  }

  :global(.swiper-button-prev):after {
    content: 'prev';
  }

  :global(.swiper-button-next):hover {
    :global(.swiper-button-next):after {
      opacity: 1;
    }
  }
  :global(.swiper-scrollbar) {
    display: none;
  }
  :global(.swiper-slide) {
    width: fit-content !important;
  }
}
