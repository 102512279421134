@use 'styles/theme.module' as *;

.main {
  width: 100%;

  .error {
    input {
      border-color: $error;
      color: $error;
    }
  }

  .login_btn {
    margin-top: 6px;
    font-weight: 600;
    color: $white;
    border-color: $mainOrange;
    text-transform: capitalize;
    border-radius: 10px;
    @include btnRed;
    &:hover {
      @include btnRedHover;
    }
  }
}

.modal_login:global(.MuiContainer-root) {
  display: flex;
  justify-content: center;
  padding-left: 0;
  padding-right: 24px;
  min-width: 320px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../assets/heroImg/fon.webp');

  @include media($until: $sm) {
    padding: 30px;
    min-width: 280px;
  }

  @include media($until: 378) {
    justify-content: center;
    padding: 0px;
  }


  :global(.MuiBox-root) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: normal;
    overflow: visible;

    form {
      max-width: 440px;
      min-width: 400px;

      @include media($until: $md) {
        max-width: 320px;
        min-width: unset;
        width: 100%;
      }
    }

    &.is_mobile {
      width: 100%;
      min-width: 274px;
    }
  }

  @include media($until: $md) {
    background-image: none;
  }

  @include media($until: $sm) {
    img {
      margin: auto;
    }
  }

  @include media($until: $xsm) {
    flex-direction: column-reverse;
  }
}

.input_content {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 12px;

  .left_side {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }
}

a.link {
  margin: 0 4px;
  text-decoration: underline;
  font-size: 0.8rem;
}

.action_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .info {
    display: flex;
    flex-basis: 40%;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    a.link {
      margin: 0 auto;
    }
  }

  .action {
    width: 100%;
    margin-top: 10px;

    .have_acount {
      display: flex;
      justify-content: center;
      margin: 12px;
      align-items: center;
      font-size: 14px;

      @include media($until: 378) {
        font-size: 12px;
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;

  &.is_mobile {
    flex-direction: row !important;
    justify-content: space-between !important;

    .title {
      color: $white;
    }
  }

  .title {
    color: $lightRed;
    font-size: 18px;
  }

  .hidden {
    display: none;
  }
}

.modal_bg {
  display: flex;
  max-width: 420px;
  min-height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @include media($until: $md) {
    min-height: 320px;
    img {
      width: 100%;
    }
  }

  @include media($until: $sm) {
    img {
      width: 330px;
    }
  }

  @include media($until: $xsm) {
    img {
      width: 330px;
      transform: translate(-17px, 0);
    }
  }

  @include media($until: $xss) {
    img {
      width: 300px;
      transform: translate(-7px, 0);
    }
  }
}

.divider {
  margin: 20px;
  height: 2px;
  width: 20%;
  background: $white;
}
