@use 'styles/theme.module' as *;

li.menu_mobile {
  border-radius: 8px;
  border: 1px solid #fff;
  background: $secondaryBlueMobile;
  padding: 15px;
  margin-bottom: 6px;
  height: 48px;
  a {
    color: $white;
    width: 100%;
    svg {
      width: 24px;
      fill: $white;
    }
  }
  &.hidden {
    background-color: transparent;
    border: 0;
  }
}
.top_mobile {
  display: flex;
  flex-direction: column;
  gap: 0;
  .img_logo {
    display: flex;
    margin: 0 auto;
  }
  .anchor_title {
    color: $white;
    text-align: left;
    margin-bottom: 16px;
    text-transform: capitalize;
  }
  .action_btn {
    display: flex;
    gap: 0;
    justify-content: space-around;
    margin-bottom: 34px;
    .login_btn,
    .sign_btn {
      max-width: 150px;
      width: 40%;
      height: 48px;
    }
    &.hasToken {
      justify-content: flex-end;
      .anchor_title {
        margin-bottom: 0;
        font-size: 18px;
      }
    }
    .sign_btn {
      margin-top: 6px;
      font-weight: 600;
      color: $white;
      border-color: $mainOrange;
      text-transform: capitalize;
      border-radius: 10px;
      @include btnRed;
      &:hover {
        @include btnRedHover;
      }
    }
  }
  :global(.MuiSvgIcon-root) {
    color: $white;
  }
}

:global(.MuiAutocomplete-popper) {
  li {
    background-color: transparent;
  }
}

.top_mobile {
  font-weight: 600;
  .login_btn {
    margin-top: 6px;
    font-weight: 600;
    background: transparent;
    border: 1px solid;
    color: $mainOrange;
    border-color: $white;
    text-transform: capitalize;
    margin-right: 12px;

    &:hover {
      border-color: $mainOrange;
      background: $white;
      color: $mainOrange;
    }
  }

  .sign_btn {
    margin-top: 6px;
    font-weight: 600;
    color: $mainOrange;
    background: $white;
    border-color: $white;
    text-transform: capitalize;

    &:hover {
      background: $mainOrange;
      border-color: $mainOrange;
      color: $white;
    }
  }
}
