@use 'styles/theme.module' as *;

.main_area {
  padding: 0 12px;
  height: calc(100vh - 100px);
  overflow: hidden;

  button {
    color: $white;
  }

  .main_title {
    text-transform: uppercase;
    margin: 0 0 12px 0;
    color: $lightRed;
  }

  .methods_block {
    border-radius: 15px;
    padding: 0;

    .history_block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      border-radius: 15px;
      margin: 6px;
      max-height: 780px;

      .history_block_lists {
        height: calc(100vh - 300px);
        overflow: hidden auto;
        width: 100%;
        padding: 12px;

        @include media($until: 478) {
          padding: 0;
        }

        .empty {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }

      span,
      svg {
        opacity: 0.5;
      }

      span {
        font-size: 12px;
      }

      .history_item,
      .history_item_title {
        display: flex;
        justify-content: space-between;
        font-size: 0.6rem;
        background-color: rgba(124, 114, 138, 0.206);
        padding: 6px 12px;
        align-items: center;
        border-radius: 4px;
        margin-bottom: 8px;
        width: 100%;

        .pending,
        .failed,
        .canceled,
        .completed,
        .approved,
        .returned {
          flex-shrink: 0;
          padding: 2px 5px;
          margin-left: 10px;
          border-radius: 4px;
          width: 65px;
          text-align: center;
          font-weight: 500;
          color: #c2e0ff;
          text-transform: uppercase;
          font-size: 0.6rem;

          @include media($until: $xsm) {
            width: auto;
            font-size: 0.3rem;
            overflow-wrap: unset;
          }
        }

        p {
          min-width: 90px;
          max-width: 100px;
          text-align: center;
          font-size: 0.6rem;
          @include media($until: $xsm) {
            font-size: 0.5rem;
            min-width: 40px;
            max-width: 50px;
            overflow-wrap: break-word;
          }
        }
      }
      .history_item_title {
        background-color: $mainOrange;
        padding: 8px;
        text-align: center;
        font-size: 0.9rem;
      }

      .pending {
        background-color: rgba(0, 53, 117, 0.5);
      }

      .completed, .approved{
        background-color: rgba(0, 169, 31, 0.5);
      }

      .failed,
      .canceled {
        background-color: rgba(255, 2, 99, 0.772);
      }

      .returned {
        background-color: rgba(255, 150, 2, 0.772);
      }
    }
  }

  @include media($until: $sm) {
    width: 100vw;
    height: calc(100vh - 138px);
  }
}
