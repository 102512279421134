@use 'styles/theme.module' as *;

.main_container {
  height: 350px;
  background-color: $secondaryBlueMobile;
  width: 72px;

  @include media($until: $md) {
    width: 100vw;
    height: 40px;
    margin-left: 0;
  }

  display: flex;
  margin: 24px 6px 30px 42px;

  .sliderWrapperVertical {
    height: 317px;
    width: 72px;

    @include media($until: $md) {
      width: 100vw;
      height: 66px;
      margin-top: 13px;
    }

    margin-top: 15px;
    background-color: $secondaryBlueMobile;
    transform: rotate(180deg);

    .img_style {
      img {
        height: 60px;
        border-radius: 8px;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3);
        background: rgb(36 43 58 / 56%);
        width: 100% !important;
        padding: 6px;
      }
    }

    :global(.swiper-button-next),
    :global(.swiper-button-prev) {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      opacity: 0.8;
      left: 50%;

      @include media($until: $md) {
        display: none;
      }

      transform: rotate(270deg);
      transform-origin: left center;
    }

    :global(.swiper-button-next) {
      top: 30px;
    }

    :global(.swiper-button-prev) {
      top: auto;
      bottom: -10px;
    }

    :global(.swiper-button-next):after,
    :global(.swiper-button-prev):after {
      content: 'next';
      color: $white;
      font-size: 28px;
    }

    :global(.swiper-button-next.swiper-button-disabled),
    :global(.swiper-button-prev.swiper-button-disabled) {
      display: none;
    }

    :global(.swiper-button-prev):after {
      content: 'prev';
    }

    :global(.swiper-button-next):hover {
      :global(.swiper-button-next):after {
        opacity: 1;
      }
    }

    :global(.swiper-wrapper) {
      top: 28px;
      justify-content: center;

      @include media($until: $md) {
        top: 15px;
      }

      :global(.swiper-slide) {
        height: 22px;

        @include media($until: $md) {
          height: 30px;
        }

        display: flex;
        justify-content: center;
      }
    }

    :global(.swiper-scrollbar) {
      display: none;
    }

    :global(.swiper-slide) {
      img {
        transform: rotate(180deg);
        width: 32px;
        height: 38px;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
