@use 'styles/theme.module' as *;

.main {
  .games_container {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 600px;

    .games {
      display: inline-grid;
      grid-template-columns: repeat(6, 1fr);
      min-height: 600px;
      gap: 20px;
      .gameItem {
        height: fit-content;
        margin: 0;
        gap: 20px;
        img {
          max-width: 250px !important;
        }
      }
      @include media($until: $sm) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
      @media (max-width: 381px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
      }
      @include media($from: $sm, $until: $md) {
        grid-area: 1/1;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
      }
      @include media($from: $md, $until: $lg) {
        grid-area: 1/1;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
      }
      @include media($from: $xl) {
        grid-area: 1/1;
        grid-template-columns: repeat(8, 1fr);
        gap: 20px;
      }
    }
  }
  .showMore {
    margin: 24px auto;
    width: 80%;
    button {
      color: $white;
      background-color: rgba(243, 246, 253, 0.085);
      &:hover {
        background-color: rgba(155, 162, 175, 0.05);
      }
    }
  }
}
