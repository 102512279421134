@use 'styles/theme.module' as *;

.container {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  color: $mainOrange;
  font-size: 20px;
  min-height: 550px;
  text-align: center;

  .btn {
    min-width: 100px;
    margin-top: 6px;
    font-weight: 600;
    color: $white;
    background: $mainOrange;
    border-color: $mainOrange;
    text-transform: capitalize;
    padding: 6 12px;

    &:hover {
      background: $white;
      border-color: $white;
      color: $mainOrange;
    }
  }
}
