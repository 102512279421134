@use 'styles/theme.module' as *;

.mainModal {
  :global(.MuiBackdrop-root) {
    background-color: rgba(28, 28, 28, 0.5);
  }
  .content {
    @include media($until: $md) {
      width: 100vw;
      top: 0;
      left: 0;
      transform: translate(0, 0);
      height: 100vh;
      border-radius: 0;
    }
  }
}
