$warning: #b80909;
$error: #cd0913;
$success: #4caf50;
$green: #0b9c0f;
$darkGreen: #2a910b;
$lightGreen: #58f45d;
$mainOrange: #df2a29;
$mainOrangeOld: #de5000;
$lightOrange: #ff9753;
$yellow: #ff9800;
$moreLightOrange: #ff98005e;
$darkOrange: #c13202;
$mainBlue: #0d131c;
$neonBlue: #22408c;
$secondaryBlue: #242835;
$secondaryBlueMobile: #2f323f;
$mainGrey: #d3d3d3;
$BgGrey : #2f323f;
$mainLightGrey: rgb(144 144 144 / 47%);
$lightGrey: rgb(144, 144, 144);
$greyBGLevel: #2b2e3a;
$bgProgress: rgba(255, 17, 0, 0.248);
$greyBGGradient: linear-gradient(319deg, #252731 1.64%, rgba(37, 39, 49, 0.1) 101.25%);

$white: #ffffff;
$red: #aa0000;
$lightRed: #ef3434;
$darkRed: #720203;
$pinkMagenta: #9c27b0;
$pinkMagentaDark: #6c187a;

:export {
  mainwarning: $warning;
  BgGrey: $BgGrey;
  mainOrangeOld: $mainOrangeOld;
  greyBGGradient: $greyBGGradient;

  yellow: $yellow;
  success: $success;
  error: $error;
  neonBlue: $neonBlue;
  mainOrange: $mainOrange;
  mainBlue: $mainBlue;
  secondaryBlue: $secondaryBlue;
  mainGrey: $mainGrey;
  white: $white;
  lightOrange: $lightOrange;
  darkOrange: $darkOrange;
  red: $red;
  lightRed: $lightRed;
  darkRed: $darkRed;
  green: $green;
  lightGreen: $lightGreen;
  darkGreen: $darkGreen;
  mainDarkGrey: $mainLightGrey;
  moreLightOrange: $moreLightOrange;
  pinkMagenta: $pinkMagenta;
  pinkMagentaDark: $pinkMagentaDark;
  greyBGLevel: $greyBGLevel;
  lightGrey: $lightGrey;
  bgProgress: $bgProgress;
}
