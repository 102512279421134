@use 'styles/theme.module' as *;

.main_area {
  padding: 0 12px;
  min-width: 600px;
  height: calc(100vh - 128px);
  max-height: 862px;
  overflow: hidden auto;

  .haveNoBonus_area {
    background-color: #161d31;
    height: 150px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .haveNoBonus_box {
      margin: auto;
      text-align: center;

      svg,
      p {
        opacity: 0.5;
      }
    }
  }

  .main_title {
    text-transform: uppercase;
    margin: 0 0 12px 0;
    color: $lightRed;
  }
  .main_bonus_info {
    p {
      font-size: 0.8rem;
      color: #a2a2a2;
      text-align: end;
      margin: 0px 0 6px;
      padding: 8px;
    }
  }
  .bonuses_area {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;

    @include media($from: $md) {
      flex-direction: column;
    }

    .bonus_info {
      background-color: $BgGrey;
      height: 126px;
      border-radius: 15px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 6px 20px;
      @include media($until: $md) {
        flex-direction: column;
        height: auto;
        width: 300px;
        padding: 12px;
      }

      .bonus_title_area {
        display: flex;
        flex-direction: column;
        padding-left: 10px;

        div {
          display: flex;
          gap: 5px;
          align-items: flex-start;

          @include media($until: $md) {
            align-items: center;
          }
        }
        @include media($from: 3840) {
          min-width: 300px;
        }
      }

      .button_area {
        display: flex;
        justify-content: center;
        gap: 6px;
        margin-right: 5px;

        @include media($until: $md) {
          flex-direction: column;
          width: 80%;
          margin-bottom: 10px;
          gap: 10px;
        }

        button {
          font-size: 11px;
          color: $white;
        }
      }
      &.isActive {
        background-color: #ffa044;
      }

      &.isActivated {
        background-color: #616161;
        filter: blur(1.2px);
        span {
          color: grey !important;
        }
      }

      .info_amount {
        display: flex;
        justify-content: space-between;
        min-width: 300px;
        .item {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 50%;
          padding: 4px;
          &.weiger_info {
            flex-direction: column;
            .img {
              img {
                width: 47px;
                border-radius: 8px;
                border: 0.8px solid white;
              }
              display: flex;
              width: 100%;
              justify-content: center;
            }
            .img_title {
              font-size: 0.76rem;
              margin-top: 4px;
            }
            @include media($from: $xl) {
              margin: 0 4px;
            }
            @include media($from: $xxl) {
              margin: 0 20px;
            }
          }
          @include media($until: $md) {
            width: 100%;
           justify-content: center;
           gap: 4px;
          }
          @include media($from: $sm, $until: $lg) {
            width: 100%;
          }
          span {
            font-weight: 900;
            font-size: 0.8rem;
          }
        }
        @include media($from: $xxl) {
          min-width: 560px;
        }
        @include media($until: $md) {
          flex-direction: column;
          min-width: unset;
          width: 100%;
          margin: 12px 0;
        }
        @include media($from: $sm, $until: $lg) {
          flex-direction: column;
          align-items: center;
          min-width: 118px;
        }
      }
    }
  }

  @include media($until: $md) {
    width: 100vw;
    min-width: unset;
  }
}
