@use 'styles/theme.module' as *;

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 100px);
  max-height: 862px;
  .notifications_list {
    width: 100%;
    padding: 0 12px;
    min-width: 550px;
    opacity: 0.8;
    height: calc(100vh - 100px);
    max-height: calc(100vh - 150px);
    overflow: hidden auto;
    @include media($until: $sm) {
      min-width: 100vw;
      max-height: calc(100vh - 174px);
    }
  }
  button {
    margin: 15px;
    @include media($until: $sm) {
      max-width: calc(100% - 26px);
    }
  }
}
