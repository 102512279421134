@use 'styles/theme.module' as *;

.menu_header {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  height: 234px;
  background-color: $greyBGLevel;
  background-position: bottom center;
  background-size: 94%;
  background-repeat: no-repeat;
  .box {
    background-image: linear-gradient($lightOrange 0%, $mainOrange 30%);
  }
  .title {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .cashBack {
      text-align: left;
    }
    .levelName {
      color: $lightOrange;
      font-size: 1.1rem;
      font-weight: 800;
    }
  }
  .progress_bar {
    width: 100%;
    height: 10px;
    background-color: rgb(21 29 49);
    margin-bottom: 3px;
    display: table;
    align-items: center;
    padding: 2px;
    border-radius: 8px;
    .border {
      width: 80%;
      border: 2px dashed $white;
      height: 1px;
    }
    :global(.MuiLinearProgress-root) {
      height: 14px;
      border-radius: 8px;
      background-color: rgb(184 188 195);
      :global(.MuiLinearProgress-bar) {
        background-color: #7ce511;
      }
    }
  }
  .progress_label {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }
}
