body {
  margin: 0;
  font-family: system-ui,'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 a {
  color :  grey
 }
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

::-webkit-scrollbar {
  width: 3px!important;
  height: 8px!important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: #2b2e598c;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2b2e59;  
}