@use 'styles/theme.module' as *;

.wheel_container {
  --wheel-size: 400px;
  --wheel-size-small: 270px;
  --wheel-container-size: 430px;
  --wheel-container-size-small: 290px;
  --wheel-slice-spacing: 50px;
  --wheel-border-size: 5px;
  --wheel-color: #ff9753;
  --neutral-color: rgb(243, 243, 243);
  --bg-wheel: rgb(2, 2, 33);
  --border-right: #3c2026;
  --PI: 3.14159265358979;
  --nb-item: 0;
  --item-nb: 0;
  --selected-item: 0;
  --nb-turn: 5;
  --spinning-duration: 4s;
  --reset-duration: 0.25s;
  --font: Impact, sans-serif;

  display: flex;
  position: relative;
  box-sizing: content-box;
  width: calc(var(--wheel-container-size) + 2 * var(--wheel-border-size));
  height: calc(var(--wheel-container-size) + 2 * var(--wheel-border-size));
  padding: 3px;
  margin: auto;
  background: var(--bg-wheel);
  background: linear-gradient(
    45deg,
    rgba(2, 2, 33, 0.6) 15%,
    rgba(67, 67, 74, 1) 32%,
    rgba(172, 169, 189, 1) 47%,
    rgba(172, 169, 189, 1) 57%,
    rgba(67, 67, 74, 1) 74%,
    rgba(2, 2, 42, 1) 90%
  );
  color: var(--neutral-color);
  border: solid var(--wheel-color) 3px;
  border-radius: 50%;
  user-select: none;

  @include media($until: 500) {
    width: calc(var(--wheel-container-size-small) + 2 * var(--wheel-border-size));
    height: calc(var(--wheel-container-size-small) + 2 * var(--wheel-border-size));
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: solid transparent 20px;
    border-left-width: 0;

    @include media($until: 500) {
      border: solid transparent 14px;
    }
  }

  &::before {
    right: 7px;
    border-right-color: var(--border-right);

    @include media($until: 500) {
      right: 4px;
    }
  }

  &::after {
    right: -2px;
    border-right-color: var(--wheel-color);
  }

  .wheel {
    display: flex;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: var(--wheel-size);
    height: var(--wheel-size);
    overflow: hidden;
    border-radius: 50%;
    border: solid var(--wheel-color) var(--wheel-border-size);
    transform: rotate(0deg);
    cursor: pointer;
    background-image: url('../../../assets/wheel/bg_wheel.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include media($until: 500) {
      width: var(--wheel-size-small);
      height: var(--wheel-size-small);
    }

    &.spinning {
      transition: transform var(--spinning-duration);
      transform: rotate(
        calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1)))
      );
    }
  }

  .wheel_item {
    display: flex;
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    width: 5%;
    transform-origin: center left;
    transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));
    color: var(--neutral-color);
    text-align: right;
    padding: 0 25px 0 70px;

    @include media($until: 500) {
      padding: 0 5px 0 45px;
    }

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      box-sizing: border-box;
      z-index: -1;
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-left: 0px;
      opacity: 0.25;
      --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size) / 2);
      --slice-width: calc((var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing));
      border: solid transparent calc(var(--slice-width) / 2);
      border: solid transparent 1px;
      border-left: solid transparent 0;
      border-right: solid var(--neutral-color) calc(var(--wheel-size) / 2);
    }

    .stone {
      width: 65px;
      transform: rotate(90deg);

      @include media($until: 500) {
        width: 40px;
      }
    }

    .bonus_text {
      transform: rotate(90deg);
      display: block;
      font-size: 22px;
      font-family: var(--font);
      font-weight: lighter;

      @include media($until: 500) {
        font-size: 14px;
      }
    }
  }
  .logo {
    position: absolute;
    top: 76px;
    left: 36%;
    z-index: 2;
    width: 132px;
    @include media($until: 500) {
      top: 60px;
      left: 38%;
      width: 71px;
    }
  }
}

.start {
  --border-right: #3c2026;
  --wheel-color: #ff9753;
  --start-hover-color: #a0df5780;
  --start-color: #cddc39;
  --box-hover-color: #782e2e;
  --box-color: #a6260c;

  display: flex;
  position: absolute;
  content: '';
  background-color: var(--border-right);
  width: 70px;
  height: 70px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: 0px 0px 15px 1px var(--border-right);
  border: 2px solid var(--wheel-color);
  transition: all ease-in-out 0.05s;
  justify-content: center;
  align-items: center;

  svg {
    width: 55px;
    height: 55px;
    cursor: pointer;
    color: var(--start-color);
    box-shadow: 0px 0px 0px 2px var(--box-color);
    border-radius: 50px;
    transition: all ease-in-out 0.05s;
  }

  &.clicked,
  &:hover {
    svg {
      width: 49px;
      height: 49px;
      margin: 14%;
      color: var(--start-hover-color);
      box-shadow: none;
    }

    box-shadow: 0px 0px 0px 4px var(--box-hover-color);
  }

  @include media($until: 500) {
    width: 50px;
    height: 50px;

    svg {
      width: 40px;
      height: 40px;
      margin: 7%;
    }

    &.clicked,
    &:hover {
      svg {
        width: 38px;
        margin: 9%;
        height: 38px;
      }
    }
  }
}
