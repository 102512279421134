@use 'styles/theme.module' as *;

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: $secondaryBlueMobile;
  background-image: url('../../assets/wheel/bg_content.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
  min-height: 600px;

  .delay {
    font-size: 1.5rem;
    margin: 0 auto;
    background-color: #00000099;
    padding: 12px;
    position: absolute;
    text-align: center;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    padding: 20px;
    width: 134px;
  }
  .delay_text {
    width: 100%;
    top: 80px;
    position: absolute;
    display: flex;
    background-color: #00000058;
    z-index: 4;
    h2 {
      padding: 20px;
      font-size: 2.6rem;
      margin: 0 auto;
      text-align: center;

      @include media($until: $md) {
        font-size: 1.8rem;
      }
      @include media($until: $xsm) {
        font-size: 1.8rem;
      }
    }
  }
}
