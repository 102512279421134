@use 'styles/theme.module' as *;

.vertical_carousel {
  width: 72px;
  height: 349px;
  background-color: $secondaryBlueMobile;
}
.category_section {
  padding: 4px;
  margin-top:10px;
.category_show_all{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
  @include media($until: $sm) {
    padding: 12px 4px;
  }
  @include media($from: $sm, $until: $md) {
    padding: 12px 14px;
  }
  .category_carousel {
    width: calc(100% - 93px);
    transform: translateX(125px);
    @include media($until: $sm) {
      width: 99%;
      transform: translateX(3px);
    }
    @include media($from: $sm, $until: $md) {
      width: calc(100% - 114px);
      transform: translateX(125px);
    }
  }
  .ads{
    width: 100%;
    transform: translateX(11px);
  }
}
.link,
.link_provider {
  flex-basis: 90%;
  display: flex;
  align-items: center;
  padding-left: 12px;
  min-height: 62px;
  img,
  svg {
    width: 24px;
    height: 24px;
  }
  a {
    color: $white;
    font-weight: 400;
    &:hover {
      color: $mainOrange;

      img,
      svg {
        filter: invert(72%) sepia(59%) saturate(3061%) hue-rotate(10deg) brightness(91%)
          contrast(101%);
      }
    }
  }
}
