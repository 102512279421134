@use 'styles/theme.module' as *;

.main {
  background-image: linear-gradient($mainOrange 10%, $lightRed 80%);
  margin: 10px 0;
  width: 100%;
  border-radius: 10px;
  span,
  svg {
    color: $white;
  }
}
.title {
  background-image: linear-gradient($lightRed 0%, $mainOrange 80%);
  background-clip: text;
  width: 100%;
  p {
    color: transparent;
  }
}
