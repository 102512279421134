@use 'styles/theme.module' as *;

.general_area {
  min-height: 400px;
  height: auto;
  margin: 15px 0;
  padding: 20px;
  max-width: 2048px;
  margin: 0 auto;
  .scroll_to_up {
    animation-name: scroll_to_up;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    transform: translateY(800px);
    opacity: 0;
    position: fixed;
    right: 12px;
    bottom: 82px;
    z-index: 5;
    cursor: pointer;
    color: $white;
    transition: 0.5s;
    border-radius: 100%;
    background-color: $mainOrange;
    border: none;
    width: 45px;
    height: 45px;
    &:hover {
      transition: 0.5s;
      background-color: rgb(207, 43, 2) !important;
      color: $white;
    }
    svg {
      font-size: 30px;
    }
  }
  .community_icon {
    filter: brightness(0) saturate(100%) invert(24%) sepia(100%) saturate(2014%) hue-rotate(343deg)
      brightness(88%) contrast(100%);
    border-radius: 50%;
    width: 28px;
    padding: 5px;
  }
  .community_area {
    .community_info {
      display: flex;
      flex-direction: column;
      margin: 32px 0 22px 0;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: space-between;
      @include media($until: $md) {
        flex-direction: row;
      }
      a {
        color: $white;
        font-size: 0.8rem;
      }
    }

    .logo_area {
      text-align: left;

      .logo {
        display: flex;
        @include media($until: $md) {
          width: 110px;
        }
      }

      @include media($until: $md) {
        padding: 16px 0 0 0;
      }
    }
  }
  .links_div {
    @include media($until: $md) {
      display: none;
    }
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 160px;
    span {
      font-size: 14px;
    }
    div {
      margin: 0;
      color: $mainLightGrey;
      font-size: 0.4rem;
      padding: 1px 2px ;

      &:hover {
        color: $mainOrange;
      }
       &:active {
        background-color: transparent;
      }
    }
    .links_title {
      margin-bottom: 20px;
      font-size: 10px;
      line-height: 12px;
      color: $white;
      text-transform: uppercase;
      @include media($until: $sm) {
        margin-bottom: 15px;
      }
    }
    .links,
    a {
      margin-bottom: 10px;
      color: $mainLightGrey;
      font-size: 14px;
      line-height: 20px;
      @include media($until: $sm) {
        font-size: 12px;
        margin-bottom: 7px;
      }
      &:hover {
        color: $mainOrange;
      }
    }
   
  }
  .main_span {
    background-color: $secondaryBlueMobile;
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
    span,
    svg {
      color: $white;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    margin: auto;
    @include media($until: $md) {
      flex-direction: column;
      width: unset;
    }
  }
  .mobile_version {
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: 0 auto;
    @include media($from: $md) {
      display: none;
    }
  }
  .site_info {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    gap: 10px;
    .text {
      color: $white;
      line-height: 15px;
      font-size: 12px;
    }
    @include media($until: $md) {
      margin-top: 28px;
    }
  }
}

@keyframes scroll_to_up {
  0% {
    transform: translateY(40px);
    opacity: 0.3;
  }
  40% {
    transform: translateY(20px);
    opacity: 0.5;
  }
  70% {
    transform: translateY(10px);
    opacity: 0.7;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
