@use 'styles/theme.module' as *;

.aboutUs_main {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  font-size: 0.9rem;
  @include media($from: $md) {
    align-items: center;
  }
  @include media($until: $md) {
    flex-direction: column-reverse;
  }
  .box_area {
    word-wrap: break-word;
    flex-basis: 60%;
    @include media($from: $md, $until: $xl) {
      flex-basis: 50%;
    }
    @include media($until: $md) {
      flex-basis: 100%;
    }
    figure {
      margin: 0;
    }
  }

  .img_box {
    display: flex;
    margin: 0 auto;
    flex-basis: 30%;
    max-width: 500px;
    @include media($from: $lg, $until: $xl) {
      flex-basis: 40%;
    }
    @include media($from: $md, $until: $lg) {
      flex-basis: 50%;
    }
  }
}
