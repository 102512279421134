@use 'styles/theme.module' as *;

.main_area {
  padding: 0 12px;
  min-width: 550px;
  height: calc(100vh - 150px);
  max-height: 860px;
  overflow: hidden auto;
  button {
    color: $white;
  }
  .main_title {
    display: flex;
    text-transform: uppercase;
    margin: 0 0 12px 0;
    color: $lightRed;
    svg {
      color: $white;
      margin: 0 0 0 12px;
    }
  }
  .levels_block,
  .all_levels {
    background-color: #161d31;
    border-radius: 15px;
    padding: 12px;

    .benefit {
      margin: 10px 0 0;
      background-color: #3d4760;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 12px;

      .benefit_title {
        padding: 12px;
        width: 100%;
        display: flex;
        background-color: rgb(39 37 50 / 0.8);
        justify-content: space-between;
        gap: 20px;
        @include media($until: $xsm) {
          gap: 4px;
        }
        p {
          font-size: 0.8rem;
          text-align: center;
          @include media($until: $xsm) {
            font-size: 0.6rem;
          }
          &.title {
            font-weight: 800;
            background-clip: text;
            background-image: linear-gradient($lightOrange 15%, $mainOrange 66%);
            color: transparent;
            font-size: 1.2rem;
            @include media($until: $xsm) {
              font-size: 1rem;
              line-height: 1.1rem;
            }
          }
        }
        .item {
          display: flex;
          width: 100%;
          .item_box {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 100%;
          }
          .vertical_line {
            width: 1px;
            margin: 0 5px;
            background-color: rgb(122 114 164 / 40%);
          }
        }
      }
      .benefit_content {
        width: 100%;
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        padding: 12px 0;
        @include media($until: $xsm) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        .item {
          background-color: rgb(39 37 50 / 0.8);
          border-radius: 8px;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding: 12px;
          p {
            font-size: 0.8rem;
            width: 80%;
          }
          .img {
            margin-right: 8px;
          }
          .icon {
            svg {
              display: block;
              color: $green;
            }
          }
          &.disabled {
            p,
            .img {
              opacity: 0.3;
            }
            .icon {
              svg {
                visibility: hidden;
              }
            }
          }
        }
      }
      &.isActive {
        background-color: #3d4760;
      }
    }
  }
  .all_levels {
    margin-top: 20px;
    background-color: transparent;
    .benefit {
      background-color: rgb(43 44 59);
      padding: 12px;
      .benefit_name {
        text-transform: uppercase;
        margin: 16px 0;
        font-weight: 800;
      }
      .benefit_img {
        width: 100%;
        height: 235px;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px;
        @include media($until: $xsm) {
          background-size: cover;
        }
        @include media($until: $xss) {
          background-size: cover;
        }
      }
      .benefit_title {
        background-color: transparent;
        gap: 0;
        padding: 12px 0;
        p {
          &.title {
            font-size: 1rem;
          }
        }
      }
      .benefit_content {
        display: flex;
        flex-direction: column;
      }
    }
    .title {
      font-size: 1.2rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 0.7rem;
        text-align: center;
      }
    }
    .content {
      width: 100%;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(2, minmax(0, 522px));
      justify-content: center;
      @include media($until: $xsm) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }

  @include media($until: $sm) {
    min-width: 100vw;
    height: calc(100vh - 110px);
    max-height: unset;
  }
  @include media($until: $md) {
    max-height: unset;
  }
  .title {
    font-size: 12px;
  }
  .bg_levels {
    background-position: center right;
    background-size: 60%;
    @include media($from: $xs, $until: $xss) {
      background-size: cover;
      background-position: center left;
    }
    @include media($from: $xss, $until: $xsm) {
      background-size: 76%;
    }
  }
}
