@use 'styles/theme.module' as *;

.games_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
  .top {
    display: flex;
    width: 100%;
    justify-content: space-around;
    @include media($until: $md) {
      justify-content: flex-start;
    }
    @media (max-width: 320px) {
      margin-left: 0;
      justify-content: space-around;
    }
  }
  .loader {
    display: flex;
    min-height: 600px;
    align-items: center;
  }
  .games {
    display: inline-grid;
    grid-template-columns: repeat(4, 1fr);
    min-height: 600px;
    gap: 20px;
    .gameItem {
      height: fit-content;
      margin: 0;
      gap: 20px;
      img {
        max-width: 250px !important;
      }
    }
    @include media($until: $sm) {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    @media (max-width: 381px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    @include media($from: $sm, $until: $md) {
      grid-area: 1/1;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
    @include media($from: $md, $until: $lg) {
      grid-area: 1/1;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
    @include media($from: $xl) {
      grid-area: 1/1;
      grid-template-columns: repeat(5, 1fr);
      gap: 20px;
    }
  }

  .category_Title {
    background-image: linear-gradient($lightRed 0%, $mainOrange 80%);
    background-clip: text;
    width: 100%;
    padding: 12px 24px;

  h1 {
      margin: 0 14px;
      color: transparent;
      font-size: 1.4rem;
      font-weight: 800;
    }
  }
}
