@use 'styles/theme.module' as *;

.card {
  padding: 24px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: #1d203078;
  border-radius: 20px;
  .card_media {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .card_content {
    // border-left: 0.3px solid $lightRed;
    padding-left: 20px;
    h6 {
      color: $lightRed;
      &::before {
        content: ' \2058 ';
        color: $lightRed;
        margin-right: 6px;
        font-weight: bolder;
      }
    }
     .description {
      padding: 4px 0;
      font-size: 0.8rem;
      font-weight: 400;
    }
  }
  &.isOdd {
    flex-direction: row-reverse;
    .card_content {
      border-left: 0;
      // border-right: 0.3px solid $lightRed;

      padding-left: 0;
      padding-right: 20px;
    }
    .card_media {
      justify-content: flex-end;
    }
    @include media($until: $sm) {
      flex-direction: column;
    }
  }
  @include media($until: $sm) {
    flex-direction: column;
  }
}
