@use 'styles/theme.module' as *;

.box_card {
  display: grid;
  cursor: pointer;
  position: relative;
  margin: 10px;
  .title {
    position: absolute;
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: bolder;
    max-width: 200px;
    text-align: center;
    top: 32%;
    right: 0;
  }

  .card {
    width: 100%;
    img {
      height: auto;
      width: 100%;
    }
    .img {
      position: relative;
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        font-size: 0.7rem;
        color: white;
        position: absolute;
        bottom: 48px;
        right: 11px;
        button {
          margin: 0;
        font-size: 0.6rem;

          @include media($until: $xss) {
            min-width: 70px;
            padding: 2px;
          }
        }
         @include media($until: $xss) {
            bottom: 30px;
            right: 7px;
          }
      }
    }
  }

  :global(.MuiCard-root) {
    background-color: transparent;
    box-shadow: none;
  }
}
