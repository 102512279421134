@mixin btnRed {
  background-image: linear-gradient(to bottom, $lightRed, $red);
  color: $white;
}
@mixin btnRedHover {
  background-image: linear-gradient(to bottom, $lightRed, $darkRed);
  border-color: $white;
}

@mixin btnOrange {
  color: $white;
  background-image: linear-gradient(to bottom, $lightOrange, $mainOrange);
}
@mixin btnOrangeHover {
  background-image: linear-gradient(to bottom, $lightOrange, $darkOrange);
  border-color: $white;
}

@mixin btnGreen {
  color: $white;
  background-image: linear-gradient(to bottom, $lightGreen, $green);
}
@mixin btnGreenHover {
  background-image: linear-gradient(to bottom, $lightGreen, $darkGreen);
  border-color: $white;
}
@mixin btnMagenta {
  color: $white;
  background-image: linear-gradient(to bottom, $lightRed, $pinkMagenta);
}
@mixin btnMagentaHover {
  background-image: linear-gradient(to bottom, $lightRed, $pinkMagentaDark);
  border-color: $white;
}
