@use 'styles/theme.module' as *;

@mixin flag(
  $bg: #ffffff,
  $color: #de5000,
  $direction: right,
  $tail-length: 0.8em,
  $build-flag: true
) {
  // ======= Relative Variables =======
  $p: 0.3em;

  // ======= Flag Body =======
  @if $build-flag {
    // ~~ Display ~~
    display: inline-block;
    // ~~ Box Layout ~~
    padding: $p;
    padding-#{$direction}: $p;
    // ~~ Positioning ~~
    margin-#{$direction}: $tail-length;
    position: relative;
    // ~~ Typography ~~
    text-align: center;
    vertical-align: middle;
    line-height: 1;
    font-size: 14px;
    font-weight: 600;
  }
  // ~~ Themeing ~~
  color: $color;
  background: $bg;

  // ======= Flag Tails =======
  &:before,
  &:after {
    @if $build-flag {
      content: '';
      width: 0;
      height: 0;
      border-#{$direction}: $tail-length solid transparent;
      #{$direction}: -#{$tail-length};
      position: absolute;
      top: 0;
    }
    border-top: (0.5em + $p) solid $bg;
  }
  &:after {
    @if $build-flag {
      top: auto;
      bottom: 0;
    }
    border-top: none;
    border-bottom: (0.5em + $p) solid $bg;
  }
}

.reversed {
  @include flag($direction: left);
  top: 0;
  right: 0;
  position: absolute;
}

.box_card {
  display: grid;
  cursor: pointer;
  // max-width: 168px;
  margin: 10px;

  &:hover .card_content,
  &:hover .card_content_with_small {
    background-color: #12235f90;
    .action {
      display: flex;
    }
    .heart {
      display: block;
    }
  }

  .card {
    overflow: hidden;
    position: relative;
    width: 160px;

    grid-area: 1 / 1;
    border-radius: 8px;
    .img {
      overflow: hidden;
      height: auto;
      max-height: 210px;
      max-width: 300px;
      display: flex;
      justify-content: center;
      border-radius: 8px;
      border-bottom: 2px solid $white;
      border-top: 2px solid $white;
      &.fade_in {
        animation: fadeIn 2s;
      }
    }
    .card_with_small_games {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .logo {
        display: flex;
        position: absolute;
        top: 28px;
      }
      .small_games {
        display: flex;
        position: absolute;
        bottom: 24px;
        z-index: 2;

        img {
          margin: 2px;
          max-width: 60px;
          height: 45px;
          border: 2px solid transparent;
          border-radius: 16px;
          &:hover {
            border: 2px solid $mainBlue;
          }
        }
      }
    }
    &.isProvider {
      width: fit-content;
    }
    @include media($until: $sm) {
      width: 100px;
    }
  }
  .card_content,
  .card_content_with_small {
    grid-area: 1 / 1;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    position: relative;
    transition: background-color 0.4s ease-out;
    width: 100%;
    border-radius: 8px;

    .action {
      margin-top: 10px;
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      .play {
        width: 39px;
        height: 39px;
        margin-bottom: 6px;
        background-color: $white;
        border-radius: 50%;
        svg {
          font-size: 3.8rem;
          fill: $red;

          &:hover {
            fill: #bd1616a3;
            color: $white;
          }
        }
        &:hover {
          background-color: $white !important;
        }
      }

      .demo {
        width: 42px;
        height: 32px;
        margin-top: 6px;
        font-weight: 600;
        background: transparent;
        border: 1px solid;
        color: $white;
        border-color: $white;
        text-transform: capitalize;

        &:hover {
          background: $white;
          color: $mainBlue;
        }
      }
    }
    .heart {
      display: none;
      position: absolute;
      height: 0;
      top: 0;
      right: 0;
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.special_height {
  min-height: 200px;
  max-width: 160px;
   img {
    height: 114% !important;
  }
   @include media($until: $sm) {
      min-height: 130px;
      max-width: 100px;
    }
}
