@use 'styles/theme.module' as *;

.main {
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  padding: 14px 75px;
  max-width: 1300px;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: auto;
    background: $greyBGGradient;
    border-radius: 18px;
    max-width: 500px;
    margin: 0 auto;
    .footer {
      display: flex;
      justify-content: center;
      background-color: #2e2e36;
      padding: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      .btn {
        @include btnRed;
        &:hover {
          @include btnRedHover;
        }
      }
    }
  }

  @include media($until: $md) {
    padding: 12px 20px;
  }
  @include media($until: $sm) {
    padding: 12px;
  }

  .anounce__freespins {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    background: $greyBGGradient;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;

    .close_btn {
      position: absolute;
      color: white;
      right: 3px;
      top: 3px;
      cursor: pointer;
      z-index: 3;
    }
  }

  .anoounce__left {
    padding: 24px;
    position: relative;
    z-index: 2;
    color: $white;
    width: 100%;
    .game__title {
      font-size: 2rem;
      margin: 20px;
    }
    @include media($until: $xsm) {
      padding: 20px;
    }
  }

  .anounce__title {
    margin-bottom: 4px;
    margin-top: 8px;

    max-width: 450px;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    color: $white;
    text-indent: 20px;
  }

  .anounce__highlight,
  .anounce__highlight_small {
    color: $yellow;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 7px;
    @include media($until: $xsm) {
      font-size: 1.2rem;
    }
  }
  .anounce__highlight_small {
    font-size: 0.9rem;
    display: block;
    text-align: center;
    @include media($until: $xsm) {
      text-indent: 0;
      white-space: unset;
    }
  }

  .anounce__highlight_icon {
    width: 30px;
  }

  .anounce__description {
    max-width: 450px;
    font-weight: 400;
    font-size: 13px;
    line-height: 155%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .anounce__image {
    border-radius: 9px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      width: 100%;
      max-width: 100px;
      min-width: 80px;
      border-radius: 12px;
      border: white 1px solid;
    }
    @include media($until: $xsm) {
    }
    @include media($until: $xss) {
    }
  }
}
