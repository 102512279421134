@use 'styles/theme.module' as *;

.card {
  padding: 8px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: rgb(49 51 68);
  border-radius: 20px;
  min-height: 260px;
  .card_media {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .card_content {
    padding-left: 20px;
    h6 {
      color: $lightRed;
    }
    .description {
      height: 160px;
      overflow: auto;
      overflow-x: hidden;
      padding: 4px 0;
      font-size: 0.8rem;
      font-weight: 400;
    }
  }

  @include media($until: $sm) {
    flex-direction: column;
  }
}
