@use 'styles/theme.module' as *;

.action {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @include media($until: $xss) {
    flex-direction: column;
  }

  .playing_btn,
  .close_btn {
    margin: 6px;
    font-weight: 600;
    color: $white;
    background: $mainOrange;
    border-color: $mainOrange;
    text-transform: capitalize;
    max-width: 180px;
    @include btnRed;
    &:hover {
      @include btnRedHover;
    }
  }
  .playing_btn {
    @include btnGreen;
    &:hover {
      @include btnGreenHover;
    }
  }
}
