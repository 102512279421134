@use 'styles/theme.module' as *;

.right_side,
.top_mobile {
  font-weight: 600;

  .login_btn {
    font-weight: 600;
    background: transparent;
    border: 0;
    color: $mainOrange;

    text-transform: capitalize;
    margin-right: 12px;

    &:hover {
      border-color: $mainOrange;
      background: $white;
      color: $mainOrange;
    }
  }

  .avatar {
    width: 32px;
    height: 32px;
    background-color: white;
    color: $lightRed;
    font-size: 24px;
    text-transform: capitalize;

    &:hover {
      background-color: white !important;
    }

    p {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .sign_btn {
    min-width: 130px;
    font-weight: 600;
    color: $white;
    background: $white;
    border-color: $white;
    text-transform: capitalize;
    @include btnRed;

    &:hover {
      @include btnRedHover;
    }
  }
}

.logo {
  img {
    @include media($until: $sm) {
      width: 100px;
    }
  }
}

.bottom_menu_mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: $secondaryBlueMobile;
  height: 72px;
  padding: 0 24px;
  width: 100vw;
  color: $white;
  z-index: 11;

  .title {
    font-family: inherit;
    font-weight: 200;
    color: $white;
    font-size: 16px;
    @include media($until: $xsm) {
      font-size: 10px;
    }
  }

  @include media($until: $xss) {
    padding: 0 4px;
  }
}

.links {
  margin: 6px 8px 0;
  color: $white;
  min-width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin-left: 4px;
    font-size: 0.8rem;
    @include media($from: $md, $until: $lg) {
      font-size: 0.7rem;
    }
  }
  svg {
    fill: $white;
    @include media($from: $md, $until: $lg) {
      width: 17px;
    }
  }
  &.active {
    p {
      color: $mainOrange;
    }

    svg {
      fill: $mainOrange;
    }
  }
}

.right_side {
  display: flex;
}

.li_item {
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    margin-right: 12px;
    width: 32px;
    height: 32px;
    background-color: rgb(84 112 154 / 25%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: relative;

    .has_notifications_for_mobile {
      width: 14px;
      height: 14px;
      border-radius: 8px;
      background-color: $lightRed;
      position: absolute;
      right: 0;
      top: 0;

      span {
        color: $white;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 1px;
        margin: auto;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    svg {
      font-size: 18px;
    }
  }
}

.notification {
  display: inline;

  @include media($until: $xss) {
    display: none;
  }
}

.has_notifications {
  width: 15px;
  height: 15px;
  border-radius: 8px;
  background-color: $lightRed;
  position: absolute;
  right: 0;
  top: 0;

  span {
    color: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    font-size: 10px;
  }
}

.deposit {
  display: flex;

  button {
    margin-left: 6px;
    @include btnRed;

    &:hover {
      @include btnRedHover;
    }

    @include media($until: $xss) {
      width: 64px;
      font-size: 10px;
    }
  }
}

.container {
  display: flex;
  max-width: 2048px;

  @include media($until: $xss) {
    padding: 6px 8px;
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    @include media($from: $xss) {
      justify-content: space-between;
    }
  }
}

.navbar_noti_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .navbar_noti {
    width: 250px;
    min-width: unset;
    padding: 12px;
  }

  button {
    margin: 15px;
    max-width: calc(100% - 60px);
    @include btnRed;

    &:hover {
      @include btnRedHover;
    }
  }
}

.navBar_menu_items {
  margin-left: 60px;
  margin-bottom: 6px;

  @include media($until: $lg) {
    margin-left: 4px;
    a {
      margin: 6px 4px 0;
    }
  }
}

.img {
  width: 180px;

  @include media($until: $lg) {
    width: 100px;
  }
  @include media($until: $md) {
    width: 150px;
  }
  @include media($until: $xsm) {
    width: 110px;
  }
  @include media($until: $xss) {
    width: 105px;
  }
  @include media($until: 321) {
    width: 50px;
  }
}
