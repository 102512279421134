@use 'styles/theme.module' as *;

.general_area {
  border: 1.5px solid $white;
  width: auto;
  border-radius: 16px;
  height: 35px;
  cursor: pointer;
  .all_boxes {
    display: flex;
    padding: 1px 6px;
    height: 31px;
    justify-content: space-between;
    align-items: center;

    .balance_info {
      min-width: 74px;
      width: auto;
      max-width: 150px;
      display: flex;
      height: 50px;
      justify-content: center;
      align-items: center;
    }

    .rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .wallet_balance {
    font-size: 9px;
    line-height: 12.9px;
  }

  .wallet_type {
    font-size: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 60px;
    overflow: hidden;
    text-align: left;
    line-height: 14px;
    color: $lightRed;
    text-transform: capitalize;
  }
  @include media($until: $xss) {
    max-width: 130px;
  }
}

.balance_items {
  p {
    color: $white;
  }
}

p.wallet_type {
  text-transform: capitalize;
  position: relative;
  .has_notifications {
    background-color: $lightRed;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    left: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    color: $white;
    margin: auto;
    font-size: 9px;
  }
}
