@use 'styles/theme.module' as *;

.topSection {
  display: flex;
  @include media($from: $md) {
    justify-content: space-between;
  }
  @include media($until: $md) {
    flex-direction: column;
    gap: 30px;
  }
  form {
    display: flex;
    align-items: center;
    width: 50%;
    @include media($until: $md) {
      width: 100%;
    }
  }
  .select {
    :global(.MuiFormControl-root) {
      fieldset {
        height: 45px;
      }
      svg {
        color: $white;
      }
      :global(.MuiSelect-select) {
        img {
          height: 25px;
        }
      }
    }
  }
}

.games {
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  min-height: 600px;
  .gameItem {
    height: fit-content;
    margin: 0;
    img {
      max-width: 250px !important;
    }
  }
  @include media($until: $sm) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 381px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @include media($from: $sm, $until: $md) {
    grid-area: 1/1;
    grid-template-columns: repeat(3, 1fr);
  }
  @include media($from: $md, $until: $lg) {
    grid-area: 1/1;
    grid-template-columns: repeat(3, 1fr);
  }
  @include media($from: $xl) {
    grid-area: 1/1;
    grid-template-columns: repeat(5, 1fr);
  }
}
