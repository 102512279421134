@use 'styles/theme.module' as *;

.play_games_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  
  .top {
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }

  .video_block {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-height: 650px;
  }

  .iframe {
    margin: 30px;
    width: 80%;
    min-width: 50%;
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .iframe_close_btn {
        position: relative !important;
        width: 14px;
      }
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
      min-height: 650px;
    }
    .fullscreen {
      display: flex;
      align-items: center;
      padding-left: 12px;
      width: 100%;
      height: 62px;
      background-color: $secondaryBlue;
      border: thin solid $secondaryBlue;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      margin-top: 8px;
      button {
        margin-left: 8px;
        svg {
          width: 28px;
          cursor: pointer;
        }
      }
    }
  }
  .card {
    overflow: hidden;
    position: relative;

    height: 239px;
    grid-area: 1 / 1;
    border-radius: 8px;

    .img {
      overflow: hidden;
      height: auto;
      border-radius: 8px;
      border-bottom: 4px solid $white;
    }
  }
  .game_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 24px;

    .action {
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      .demo {
        width: 42px;
        height: 32px;
        margin-top: 6px;
        font-weight: 600;
        background: transparent;
        border: 1px solid;
        color: $white;
        border-color: $white;
        text-transform: capitalize;

        &:hover {
          background: $white;
          color: $mainBlue;
        }
      }
    }
  }
}
