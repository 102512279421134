@use 'styles/theme.module' as *;

.container_banner {
  display: flex;
  position: relative;
  @include media($until: $md) {
    margin: 16px 0 !important;
  }
  .img_container {
    width: calc(100vw - 500px);
    max-width: 1560px;
    @include media($until: $md) {
      width: 100%;
      max-width: 100vw;
      min-width: 270px;
    }
    .sliderWrapper {
      :global(.swiper-pagination-bullet) {
        width: 80px;
        @include media($until: $md) {
          width: 50px;
        }
        border-radius: 10px;
        background-color: $red;
        height: 4px;
      }
      .info {
        left: 30px;
        top: 30px;
        width: 60%;
        max-width: 440px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        position: absolute;
        z-index: 2;
        @include media($until: $xss) {
          margin: 0;
          top: 0;
          left: 0px;
          width: 80%;
        }
        @include media($from: $md, $until: $lg) {
          width: 80%;
        }

        @include media($until: $sm) {
          left: 0;
          top: 0;
          width: 90%;
        }
        @include media($until: 394) {
          width: 86%;
          p {
            line-height: 1.2;
          }
        }
        @include media($until: 321) {
          padding: 8px 10px;
        }
        .title {
          padding: 4px 8px;
          border-radius: 4px;
          margin: 2px;
          font-size: 13px;
          font-size: 2.2rem;
          line-height: 42px;
          font-weight: 900;
          color: $white;

          @include media($until: $md) {
            font-size: 1.5rem;
            background-color: rgba(0, 0, 0, 0.3);
          }

          @include media($until: $xss) {
            line-height: 39px;
            font-size: 1.6rem;
          }
        }
      }
    }

    .img {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right center;
      height: 350px;
      max-width: 1560px;
      width: calc(100vw - 500px);
      min-width: 350px;

      @include media($until: $lg) {
        width: 100%;
        max-width: unset;
        min-width: unset;
        background-position: -700px 0;
      }

      @include media($until: $md) {
        width: 100%;
        max-width: unset;
        min-width: unset;
        background-position: right center;
        height: 280px;
      }

      @include media($until: $sm) {
        width: 100%;
        max-width: unset;
        min-width: unset;
        background-position: right center;
        height: 250px;
      }
      @include media($until: $xss) {
        width: 100%;
        max-width: unset;
        min-width: unset;
        background-position: right center;
        height: 250px;
      }
    }
  }

  .info {
    left: 30px;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: absolute;
    z-index: 2;
    @include media($from: $xss, $until: $md) {
      margin: 0;
      bottom: 40px;
      left: 0px;
      width: 90%;
      font-size: 10px;
    }
    @include media($until: $xss) {
      margin: 0;
      top: 0;
      left: 0px;
      width: 60%;
      font-size: 10px;
    }
    .btn {
      margin: 20px 0 0 0;
      width: auto;
      min-width: 150px;
      max-width: fit-content;
      font-size: 12px;
      padding: 4px 14px;
      @include media($until: $xss) {
        transform: scale(0.9);
        font-weight: bold;
        min-width: 132px;
        font-size: 14px;
        margin: 10px 0 0 0;
      }
    }
    .red {
      @include btnRed;
      &:hover {
        @include btnRedHover;
      }
    }
    .orange {
      @include btnOrange;
      &:hover {
        @include btnOrangeHover;
      }
    }
    .green {
      @include btnGreen;
      &:hover {
        @include btnGreenHover;
      }
    }
  }
  .no_clip_with_icon {
    padding: 4px 8px;
    border-radius: 4px;
    margin: 2px;
    font-size: 15px;
    &:before {
      width: 32px;
      height: 32px;
      content: '\2713';
      font-size: 10px;
      margin-right: 10px;
      font-weight: bolder;
      color: $green;
    }
    @include media($until: $sm) {
      background-color: rgba(0, 0, 0, 0.4);
      font-size: 13px;
    }
  }
}
